const {createStore, combineReducers} = require('redux');
const {ControllerSpec} = require('./ControllerSpec.js');

// For engine
//class Controller {
    
// For browser
export class Controller {
    constructor(context, viewConfig, transmitter, alertFromController) {
        this.context = context;
        this.contextHere = {
            ...this.context,
            Controller: this
        };
        this.viewConfig = viewConfig;
        this.controllerSpec = {...ControllerSpec.Base, ...ControllerSpec[this.viewConfig.AppId]};
        this.viewSpec = this.controllerSpec.UI;
        this.modelSpec = null;
        this.isModelConnected = false;
        this.tracks = [];
        this.trackIdNext = 1;
        this.signInOrUp = new SignInOrUp(this.contextHere, '1');
        this.rootModel = {Key: 'R'};

        // The Redux store manages state inside the View. It notifies all view components
        // when the state changes so they can re-render or re-calculate as necessary
        this.storeRedux = createStore(reduxHandlers);
        this.queueReduxAlerts = [];
        if (alertFromController != null) {
            this.alertFromController = alertFromController;
            this.storeRedux.subscribe(this.alertFromController);
        }
        this.transmitter = transmitter;
        this.hostname = (typeof window !== 'undefined') ? window.location.hostname : viewConfig.HostName;
        this.websocketProtocol = 'wss';
        if (viewConfig.HostNameForce != null) {
            this.hostname = viewConfig.HostNameForce;
            this.websocketProtocol = 'ws';
        }
        this.websocketPort = viewConfig.WebsocketPort;
        this.transmitter = transmitter;
        this.transmitter.startSessionServer(this, 'receivedFromServer',
            this.websocketProtocol+ '://' + this.hostname + ':' + this.websocketPort);
        setTimeout(() => { this.start(); }, 1);
    }

    start() {
        this.storeRedux.dispatch({
            type: 'HANDLE_VIEW_RE_RENDER'
        }); 
    }

    // Initiated by client - ui or engine view - send to B/E through transmitter
    sendToServer(modelAction, modelPath, viewPath, model) {
        this.transmitter.sendMessageToBE(JSON.stringify({
            AppId: this.viewConfig.AppId,
            Action: 'ContinueSession',
            ModelAction: modelAction,
            ModelPath: modelPath,
            ViewPath: viewPath,
            Model: model
        }));
    }
    
    // Initiated by server/model - received from B/E through transmitter
    receivedFromServer(message) {
        if (message.Action != null) {
            switch (message.Action) {
                case 'ContinueSession':
                    let dispatch = null;
                    if (message.ModelSpec != null) {
                        
                        //  Model sent it's spec (schema) as the first action in a session
                        this.modelSpec = message.ModelSpec;
                        this.isModelConnected = true
                        dispatch = {
                            type: 'HANDLE_VIEW_RE_RENDER'
                        };
                    } else {
                        //let retCode = Controller_UpdateFromModel(this, message);
                        //console.log("receivedFromServer: ", message);
                        let retCode = this.updateFromModel(message);
                        if (retCode === true) {
                            dispatch = {
                                type: 'HANDLE_VIEW_RE_RENDER'
                            };
                        }
                    }
                    // If needed, queue up redux alert which will lead to a rerender of the view(s)
                    if (dispatch != null) {
                        this.queueReduxAlerts.push(dispatch);
                        setTimeout(() => {
                            this.storeRedux.dispatch(this.queueReduxAlerts.shift()); 
                        });
                    }
                    break;
                case 'StartSession':
                    this.transmitter.sendMessageToBE(JSON.stringify({
                        AppId: this.viewConfig.AppId,
                        Action: 'ContinueSession',
                        ModelAction: 'Watch',
                        ModelPath: [],
                        ViewPath: []
                    }));
                    break;
                case 'StopSession':
                    break;
                default:
                    break;
            }
        }
    }

    // UI and Engine views invoke this function to alert the controller to some
    // view state change that they have made to the View tree
    alertFromView(controllerAction, subAction, modelPath, viewPath, model, view) {
        switch (controllerAction) {
            case 'TO_MODEL':

                // Backend action - send to server
                this.sendToServer(subAction, modelPath, viewPath, model);
                
                // Model update action requested?
                if (view != null && view.UpdateRequested != null && view.UpdateRequested === true) {

                    // Yes: Queue up redux alert which will lead to a rerender of the view(s)
                    let dispatch = {
                        type: 'HANDLE_VIEW_RE_RENDER'
                    };
                    this.queueReduxAlerts.push(dispatch);
                    setTimeout(() => {
                        this.storeRedux.dispatch(this.queueReduxAlerts.shift()); 
                    });
                }
                break;
            case 'TO_VIEW':

                // View action - let controller process it
                // let retCode = Controller_UpdateFromView(this, subAction, modelPath, viewPath, model, view);
                let retCode = this.updateFromView(subAction, modelPath, viewPath, model, view);
                if (retCode === true) {
                
                    // Queue up redux alert which will lead to a rerender of the view(s)
                    let dispatch = {
                        type: 'HANDLE_VIEW_RE_RENDER'
                    };
                    this.queueReduxAlerts.push(dispatch);
                    setTimeout(() => {
                        this.storeRedux.dispatch(this.queueReduxAlerts.shift()); 
                    });
                }
                break;
            default:
                break;
        }
    }

    updateFromModel(message) {
        let retVal = true;
        if (message.ViewPath[0] === 'SignInOrUp') {
            this.signInOrUp.updateFromModel(message.Model);
        } else {
            if (message.ViewPath[0] === 'Tracks' && message.ViewPath.length > 1) {
                let track = parseInt(message.ViewPath[1]);
                if (track !== 'NaN' && message.ModelPath.length > 0) {
                    let trackCur = this.tracks.find(cur => parseInt(cur.id) === track);
                    if (trackCur != null) {
                        //console.log("updateFromModel: ", message);
                        let retCode = trackCur.updateFromModel(message);
                        if (retCode === false) {
                            retVal = false;
                        }
                    }
                }
            }
        }
        return retVal;
    }

    updateFromView(viewAction, modelPath, viewPath, model, view) {
        let retVal = true;
        let track = parseInt(viewPath[1]);
        let trackCur = this.tracks.find(cur => parseInt(cur.id) === track);
        switch(viewAction) {
            case 'ReRender':
                break;
            case 'SaveRequested':
                view.updateFromView(viewAction, modelPath, viewPath, model, view);
                break;
            case 'AlternativePicked':
                if (view.alternativePicked != null) {
                    if (view.alternatives[view.alternativePicked] != null && view.alternatives[view.alternativePicked].ViewSpec != null) {
                        view.alternativeCur = view.alternativePicked;
                        view.active = view.alternatives[view.alternativeCur];
                    } else {
                        let alternativePicked = view.viewSpec.Alternatives[view.alternativePicked];
                        if (alternativePicked.Label !== view.alternatives[view.alternativeCur].ViewSpec.Label) {
                            view.setAlternative(view.alternativePicked, view.alternatives[view.alternativeCur].CrumbTrail);
                        }
                    }
                }
                break;
            case 'CrumbPicked':
                break;
            case 'CrumbDropped':
                let viewItemNew = new ViewItem(this.contextHere, model.key, view.viewSpec.Table.Drilldown, [...modelPath, model.key], [...viewPath, model.key], view.crumbTrail, -1);
                if (model.model != null) {
                    viewItemNew.model = model.model;
                    let retCode = viewItemNew.nodeUpdateFromModel(view.viewSpec.Table.Drilldown, viewItemNew.active, viewItemNew.model, viewItemNew.model.ExtensionPath, 0);
                } else {
                }
                view.crumbTrail.push(viewItemNew);
                break;
            case 'CriteriaChanged':
                view.updateFromView(viewAction, modelPath, viewPath, model, view);
                retVal = false;
                break;
            default:
                break;
        }
        return retVal;
    }

}

// For engine
//class SignInOrUp {

// For browser
export class SignInOrUp {
    constructor(context, id) {
        this.context = context;
        this.contextHere = {
            ...this.context,
            SignInOrUp: this
        };
        this.id = id;
        this.RedirectURI = this.context.Controller.controllerSpec.UI.CognitoRedirectURI;
        this.LogoutURI = this.context.Controller.controllerSpec.UI.CognitoLogoutURI;
        this.IsAuthenticated = false;
    }
    
    updateFromModel(messageModel) {
        let retVal = true;
        this.model = {...messageModel};
        if (this.context.Controller.tracks.length === 0) {
            if (this.model.ExtensionPath != null && this.model.ExtensionPath.length > 0) {
                
                let extensionPathLocal = [...this.model.ExtensionPath];
                if (extensionPathLocal[0] === 'Advisors' && extensionPathLocal.length === 2) {

                    if (    this.UserId === 'amufti111@gmail.com' || 
                            this.UserId === 'daniela.y.chavez03@gmail.com' ||
                            this.UserId === 'keriohlinger@gmail.com' ||
                            this.UserId === 'subscriptions@ihcfunds.com' ||
                            this.UserId === 'hailyshigeta@gmail.com'
                        ) {
                        extensionPathLocal[1] = prompt("Advisor Number: ");
                    }
                    
                }
                if (extensionPathLocal[0] === 'AccountAccess' && extensionPathLocal.length === 2) {
                    if (    this.UserId === 'amar.d.guru@gmail.com' || 
                            this.UserId === 'hshigeta@asu.edu' || 
                            this.UserId === 'dychave1@asu.edu' || 
                            this.UserId === 'sidney@ihcfunds.com' || 
                            this.UserId === 'erin@ihcfunds.com' || 
                            this.UserId === 'keri@ihcfunds.com' || 
                            this.UserId === 'katherine@ihcfunds.com' || 
                            this.UserId === 'kim@ihcfunds.com' || 
                            this.UserId === 'katherinechuluo@gmail.com' || 
                            this.UserId === 'ngan415ly@gmail.com' || 
                            this.UserId === 'investor@ihcfunds.com' || 
                            this.UserId === 'peyton@ihcfunds.com'
                        ) {
                        extensionPathLocal[1] = prompt("Investor Email: ").toLowerCase();
                    } else {
                        extensionPathLocal[1] = this.UserId.toLowerCase();
                    }
                }
                
                let trackNew = new Track(this.contextHere, this.context.Controller.trackIdNext++, 'Tab', extensionPathLocal);
                this.context.Controller.tracks.push(trackNew);
            }
        }
        return retVal;
    }
}

// For engine
//class Track {

// For browser
export class Track {
    constructor(context, id, type, baseModelPath) {
        this.context = context;
        this.contextHere = {
            ...this.context,
            Track: this
        };
        this.id = id;
        this.type = type; // 'Tab', 'Fab', or 'Proc'
        this.baseModelPath = baseModelPath;
        this.viewItem = null
        setTimeout(() => { this.start(); }, 1);
    }
    
    start() {
        let profileGroup = this.baseModelPath[0];
        let profileGroupMemberId = null;
        if (this.baseModelPath.length > 1) {
            profileGroupMemberId = this.baseModelPath[1];
        }
        if (this.context.Controller.viewSpec.ProfileAlternatives[profileGroup] != null) {
            let viewSpec = this.context.Controller.viewSpec.ProfileAlternatives[profileGroup];
            if (viewSpec.Alternatives != null) {
                if (viewSpec.AlternativeDefault != null) {
                    let defaultAlternative = viewSpec.Alternatives.findIndex(alternativeCur => alternativeCur.Label === viewSpec.AlternativeDefault);
                    if (defaultAlternative > -1) {
                        let key = 'R';
                        let modelPath = [];
                        if (viewSpec.Alternatives[defaultAlternative].Item.Elems.length === 1 && viewSpec.Alternatives[defaultAlternative].Item.Elems.ElemType === 'Child') {
                        } else {
                            if (profileGroupMemberId != null) {
                                modelPath.push(viewSpec.Alternatives[defaultAlternative].Attr);
                                modelPath.push(profileGroupMemberId);
                                key = profileGroupMemberId.toString();
                            }
                        }
                        let viewPath = ['Tracks', this.id];
                        let crumbTrail =[];
                        this.viewItem = new ViewItem(this.contextHere, key, viewSpec, [...modelPath], [...viewPath], [...crumbTrail], defaultAlternative);
                    }
                }
            }
        }
    }
    
    updateFromModel(message) {
        let retVal = true;
        

/*
                    
                    let modelLeaf = accessLeafModel(this.modelSpec, this.modelSpec, this.rootModel, this.rootModel, [...message.ModelPath]);
                    let viewLeaf;
                    
                    // KLUDGE:
                    //window.collection = message.ModelPath[0];
                    
                    if (Array.isArray(modelLeaf)) {
                        let modelPathTip = message.ModelPath[message.ModelPath.length-1];
                        let viewItem = this.tracks[track].Item;
                        viewLeaf = viewItem.Alternatives[viewItem.AlternativeCur].Elems.find(cur => cur.SpecElem.ModelAttr === modelPathTip);
                        if (viewLeaf == null) {
                            let specElemCur = viewItem.Alternatives[viewItem.AlternativeCur].ViewSpec.Item;
                            if (specElemCur.ModelAttr == null && specElemCur.Elems != null && specElemCur.Elems.length === 1) {
                                specElemCur = specElemCur.Elems[0];
                            }
                            viewLeaf = {
                                LabelExtended: specElemCur.Label,
                                LabelFlattened: specElemCur.Label,
                                SpecElem: specElemCur,
                                CrumbTrail: viewItem.Alternatives[viewItem.AlternativeCur].CrumbTrail,
                                Controller: this,
                                
                                
                                ModelPath: [...viewItem.Alternatives[viewItem.AlternativeCur].ModelPath],
                                ViewPath: [...viewItem.Alternatives[viewItem.AlternativeCur].ViewPath],
                                Collection: [],
                                Watching: false
                                
                            }
                            if (viewItem.Alternatives[viewItem.AlternativeCur].Elems == null) {
                                viewItem.Alternatives[viewItem.AlternativeCur].Elems = [];
                                viewItem.Alternatives[viewItem.AlternativeCur].ExtensionPath = [];
                            }
                            viewItem.Alternatives[viewItem.AlternativeCur].Elems.push(viewLeaf);
                        }
                        retVal = Child_UpdateFromModel(this, viewLeaf, viewLeaf.SpecElem, modelLeaf, message.Model, modelPathTip);
                    } else {
                        let viewRoot = this.tracks[track].Item;
                        let viewPathRemaining = [...message.ViewPath];
                        viewPathRemaining.shift();
                        viewPathRemaining.shift();
                        viewLeaf = accessLeafView(viewRoot.ViewSpec, viewRoot.ViewSpec, viewRoot, viewRoot, viewPathRemaining);
                        let viewLeafAlternative = viewLeaf.Alternatives[viewLeaf.AlternativeCur];
                        retVal = Item_UpdateFromModel(this, viewLeafAlternative, viewLeafAlternative.ViewSpec.Item, modelLeaf, message.Model);
                    }
                    */
                    
        let viewPathTip = message.ViewPath[message.ViewPath.length-1];
        let alternativeDest;
        let messageModel = message.Model;
        if (!Array.isArray(message.Model)) {
            messageModel = [message.Model];
        }
        
        // Follow ViewPath to tip
        // TO DO: The following if block only traverses active alternatives. However, it is possible that
        //        a message may have data for inactive alternatives. This code should be generalized to allow that.
        let itemViewDest = this.viewItem;
        if (itemViewDest.active != null && itemViewDest.active.CrumbTrail.length > 0) {
            for (let viewPathSeg = 2, crumbSegCur = 0; 
                    viewPathSeg < (message.ViewPath.length-1) && crumbSegCur < itemViewDest.active.CrumbTrail.length; 
                    viewPathSeg++, crumbSegCur++) {
                let crumbCur = itemViewDest.active.CrumbTrail[crumbSegCur];
                if (crumbCur.viewPath.join() === message.ViewPath.join()) {
                    itemViewDest = itemViewDest.active.CrumbTrail[itemViewDest.active.CrumbTrail.length-1];
                } else {
                    viewPathSeg++;
                    let elemFound = crumbCur.active.Elems.find(cur => [...crumbCur.active.ViewPath, cur.viewSpec.Label].join() === message.ViewPath.join());
                    if (elemFound != null) {
                        itemViewDest = elemFound;
                        break;
                    }
                }
            }
        }
        if (itemViewDest.alternatives != null) {
            console.log("Track::updateFromModel - A: ", message);
            alternativeDest = itemViewDest.alternatives.find(cur => cur.ViewSpec != null && cur.ViewSpec.Label === viewPathTip);
            if (alternativeDest == null) {
            console.log("Track::updateFromModel - A 1: ", message);
                alternativeDest = itemViewDest.alternatives[0];
            }
            let alternativeDestSub = alternativeDest.Elems.find(cur => cur.viewSpec != null && cur.viewSpec.Label === viewPathTip);
            if (alternativeDestSub != null && alternativeDestSub.updateFromModel != null) {
            console.log("Track::updateFromModel - A 2: ", message);
                alternativeDest = alternativeDestSub;
            }
            /*
            if (alternativeDest.updateFromModel == null ) {
            console.log("Track::updateFromModel - A 3: ", message);
                alternativeDest = this.viewItem;
                messageModel = message.Model;
            }
            */


            if (alternativeDest.updateFromModel == null && (
                    this.viewItem.active.ViewSpec.Attr === 'AccountAccess' || this.viewItem.active.ViewSpec.Attr === 'Advisors' || this.viewItem.active.ViewSpec.Attr === 'ClientForms')
                ) {
            console.log("Track::updateFromModel - A 3: ", message);
                alternativeDest = this.viewItem;
                messageModel = message.Model;
            }


            if (alternativeDest.updateFromModel != null ) {
            console.log("Track::updateFromModel - A 4: ", message);
                let retCode = alternativeDest.updateFromModel(messageModel);
                if (retCode === false) {
                    retVal = false;
                }
            } else {
                console.log("Track::updateFromModel() NOT found - alternativeDest :", alternativeDest);
            }
        } else {
            //console.log("Track::updateFromModel - B: ", message);
            if (itemViewDest.updateFromModel != null ) {
            //console.log("Track::updateFromModel - B 1: ", message);
                let retCode = itemViewDest.updateFromModel(messageModel);
                if (retCode === false) {
                    retVal = false;
                }
            } else {
                console.log("Track::updateFromModel() NOT found - itemViewDest :", itemViewDest);
            }
        }
        return retVal;
    }

}

// For engine
//class ViewItem {

// For browser
export class ViewItem {
    constructor(context, key, viewSpec, modelPath, viewPath, crumbTrail, alternativeCur) {
        this.context = context;
        this.contextHere = {
            ...this.context,
            ViewItem: this
        };
        this.key = key;
        this.viewSpec = viewSpec;
        this.modelPath = modelPath;
        this.model = {};
        this.viewPath = viewPath;
        this.alternativeCur = alternativeCur;
        this.alternatives = [];
        this.isDirty = false;
        this.setAlternative(this.alternativeCur, crumbTrail);
    }

    setAlternative(alternativeNew, crumbTrail) {
        let viewSpecChoice;
        if (alternativeNew === -1) {
            viewSpecChoice = this.viewSpec;
            this.alternativeCur = 0;
        } else {
            this.alternativeCur = alternativeNew;
            viewSpecChoice = this.viewSpec.Alternatives[this.alternativeCur];
            if (viewSpecChoice.Item.Elems.length === 1 && viewSpecChoice.Item.Elems[0].ElemType === 'Child') {
                viewSpecChoice = viewSpecChoice.Item.Elems[0];
            }
        }
        for (let i = 0; i <= this.alternativeCur; i++) {
            if (i === this.alternativeCur) {
                this.alternatives[i] = {
                    ViewSpec: viewSpecChoice,
                    ModelPath: viewSpecChoice.ModelAttr != null ? [...this.modelPath, viewSpecChoice.ModelAttr] : [...this.modelPath],
                    ViewPath: viewSpecChoice.Label != null ? [...this.viewPath, viewSpecChoice.Label] : [...this.viewPath],
                    ReferencePath: [],
                    ExtensionPath: [],
                    Elems: [],
                    UpdateStatus: 'Base',
                    CrumbTrail: crumbTrail,
                    Actions: {}
                };
                
                // HERE: Use Elems[]
                let viewItemElemNew;
                if (viewSpecChoice.ModelAttr != null) {
                    viewItemElemNew = new ViewItemElem(this.contextHere, this.alternatives[i].ViewSpec.Label, this.alternatives[i].ViewSpec, 
                        this.alternatives[i].ModelPath, this.alternatives[i].ViewPath, this.alternatives[i].CrumbTrail);
                    this.alternatives[i].Elems.push(viewItemElemNew);
                    if (this.alternatives[i].ViewPath[this.alternatives[i].ViewPath.length-1] != null) {
                        this.setWatch(this.alternatives[i].ModelPath, this.alternatives[i].ViewPath);
                    }
                } else {
                    if (viewSpecChoice.Attr != null) {
                        if (this.alternatives[i].ViewPath[this.alternatives[i].ViewPath.length-1] != null) {
                            this.setWatch(this.alternatives[i].ModelPath, this.alternatives[i].ViewPath);
                        }
                    } else {
                        if (viewSpecChoice.Elems != null) {
                            viewSpecChoice.Elems.forEach(specElemCur => {
                                viewItemElemNew = new ViewItemElem(this.contextHere, specElemCur.Label, specElemCur, 
                                    this.alternatives[i].ModelPath, this.alternatives[i].ViewPath, this.alternatives[i].CrumbTrail);
                                this.alternatives[i].Elems.push(viewItemElemNew);
                            });
                        }
                    }
                }
                this.active = this.alternatives[i];
            } else {
                if (this.alternatives[i] == null) {
                    this.alternatives[i] = {};
                }
            }
        }
    }
    
    updateFromModel(message) {
            console.log("ViewItem::updateFromModel - C: ", message);
        let retVal = false;
        if (message.Key != null) {
            retVal = true;
            this.isDirty = false;
            if (this.model.Attrs == null) {
                this.model.Attrs = {};
                this.model.ChildAttrs = {};
            } else {
                if (this.model.ChildAttrs == null) {
                    this.model.ChildAttrs = {};
                }
            }
            if (message.ExtensionPath != null) {
                this.model.ExtensionPath = message.ExtensionPath;
            }
            for (let attrCur in message.Attrs) {
                this.model.Attrs[attrCur] = message.Attrs[attrCur];
            }
            if (message.ChildAttrs != null) {
                for (let attrCur in message.ChildAttrs) {
                    this.model.ChildAttrs[attrCur] = message.ChildAttrs[attrCur];
                }
            }
            let viewSpecSub = this.alternatives[this.alternativeCur].ViewSpec.Item != null ? 
                this.alternatives[this.alternativeCur].ViewSpec.Item 
            : 
                this.alternatives[this.alternativeCur].ViewSpec.Drilldown != null ? this.alternatives[this.alternativeCur].ViewSpec.Drilldown
            : 
                this.alternatives[this.alternativeCur].ViewSpec.Table != null ? this.alternatives[this.alternativeCur].ViewSpec.Table
            : 
                this.alternatives[this.alternativeCur].ViewSpec;
            let retCode = this.nodeUpdateFromModel(viewSpecSub, this.active, this.model, this.model.ExtensionPath, 0);
            if (retCode === false) {
                retVal = false;
            }
        }
        return retVal;
    }
    
    nodeUpdateFromModel(specItem, viewItem, modelItem, extensionPath, extensionOffset) {
        let retVal = true;
        specItem.Elems.forEach((specElemCur) => {
            let modelAttrArray = Array.isArray(specElemCur.ModelAttr) ? specElemCur.ModelAttr : [specElemCur.ModelAttr];
            let modelAttr = getExtendedModelAttr(modelItem, modelAttrArray, extensionPath, extensionOffset);
            let labelExtended = modelAttrArray.join('-') + '-' + specElemCur.Label;
            let viewElemCur = viewItem.Elems.find(cur => cur.LabelExtended === labelExtended);
            if (viewElemCur == null) {
                viewElemCur = viewItem.Elems.find(cur => cur.viewSpec.Label === specElemCur.Label);
            }
            //console.log("ViewItem::nodeUpdateFromModel - H1 - labelExtended: ", labelExtended);
            if (viewElemCur == null) {
                viewElemCur = new ViewItemElem(this.contextHere, specElemCur.Label, specElemCur, viewItem.ModelPath, viewItem.ViewPath, viewItem.CrumbTrail);
                viewElemCur.LabelExtended = labelExtended;
                viewElemCur.LabelFlattened = specElemCur.Label;
                viewItem.Elems.push(viewElemCur);
                console.log("ViewItem::nodeUpdateFromModel - H2 - labelExtended: ", labelExtended);
            }
            switch (specElemCur.ElemType) {
                case 'Primitive':
                    viewElemCur.Value = modelAttr != null && modelAttr.Value ? modelAttr.Value : '';
                    break;
                case 'Constant':
                    viewElemCur.Value = specElemCur.Value ? specElemCur.Value : '';
                    break;
                case 'Reference':
                    viewElemCur.ReferencePath = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [{Value: modelAttr.Value}] : [];
                    let referencePathRaw = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [modelAttr.Value] : [];
                    /*
                    // KLUDGE
                    if (referencePathRaw.length > 0) {
                        setTimeout(() => {
                            controller.sendToServer(
                                'Watch', 
                                ['Accounts', ...referencePathRaw], 
                                //['Tracks', 0, 'Home', 'Accounts'], 
                                [...viewItem.ViewPath],
                                {});
    
                        });
                        retVal = false;
                    }
                    */
                    break;
                case 'Embedded':
                    viewElemCur.ExtensionPath = [];
                    this.nodeUpdateFromModel(specElemCur.Item, viewElemCur.item.active, modelAttr, extensionPath, viewElemCur.ExtensionPath, 0);
                    break;
                case 'Extension':
                    if (modelAttr != null) {
                        this.nodeUpdateFromModel(specElemCur.Item, viewElemCur.item.active, modelAttr, extensionPath, extensionOffset+1);
                        if (modelItem != null && modelItem.ExtensionPath != null && modelItem.ExtensionPath.length > extensionOffset) {
                            if (extensionPath.length < modelItem.ExtensionPath.length) {
                                extensionPath.push(modelItem.ExtensionPath[extensionOffset]);
                            }
                            viewItem.ExtensionPicked = modelItem.ExtensionPath[extensionOffset];
                        }
                    }
                    break;
                case 'Child':
                    viewElemCur.ModelPath = [...viewItem.ModelPath, specElemCur.ModelAttr];
                    viewElemCur.ViewPath = [...viewItem.ViewPath, specElemCur.Label];
                    break;
                default:
                    break;
            }
        })
        return retVal;
    }

    updateFromView(viewAction, modelPath, viewPath, model, view) {
        let retVal = false;
        let attrs = {};
        this.active.Elems.forEach((elemCur) => {
            if (elemCur.viewSpec.ElemType === 'Primitive') {
                attrs[elemCur.viewSpec.ModelAttr] = {
                    Type: 'P',
                    Value: elemCur.Value,
                    Schema: {}
                };
            }
        });
        let modelNew = [{
            Key: this.key, 
            Attrs: attrs,
            ExtensionPath: this.active.ExtensionPath != null ? [...this.active.ExtensionPath] : []
        }];
        
        let retCode = this.nodeUpdateFromView(this.viewSpec, this.active, modelNew[0], modelNew.ExtensionPath, 0);
        if (retCode === true) {
            retVal = true;
        }
        setTimeout(() => {
            this.context.Controller.sendToServer(
                'Update', 
                this.modelPath, 
                this.viewPath, 
                modelNew
            );
        });
        return retVal;
    }

    nodeUpdateFromView(specItem, viewItem, modelItem, extensionPath, extensionOffset) {
        let retVal = false;
        specItem.Elems.forEach((specElemCur) => {
            let modelAttrArray = Array.isArray(specElemCur.ModelAttr) ? specElemCur.ModelAttr : [specElemCur.ModelAttr];
            //let modelAttr = getExtendedModelAttr(modelItem, modelAttrArray, extensionPath, extensionOffset);
            //let labelExtended = modelAttrArray.join('-') + '-' + specElemCur.Label;
            let labelExtended = specElemCur.Label;
            let viewElemCur = viewItem.Elems.find(cur => cur.label === labelExtended);
            switch (specElemCur.ElemType) {
                case 'Primitive':
                    if (viewElemCur != null && modelItem.Attrs[modelAttrArray[0]] != null) {
                        modelItem.Attrs[modelAttrArray[0]].Value = viewElemCur.Value != null ? viewElemCur.Value : '';
                    }
                    break;
                case 'Reference':
                    //viewElemCur.ReferencePath = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [{Value: modelAttr.Value}] : [];
                    //let referencePathRaw = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [modelAttr.Value] : [];
                    break;
                case 'Embedded':
                    //viewElemCur.ExtensionPath = [];
                    //this.nodeUpdateFromModel(specElemCur.Item, viewElemCur.item.active, modelAttr, extensionPath, viewElemCur.ExtensionPath, 0);
                    break;
                case 'Extension':
                    /*
                    if (modelAttr != null) {
                        this.nodeUpdateFromModel(specElemCur.Item, viewElemCur.item.active, modelAttr, extensionPath, extensionOffset+1);
                        if (modelItem != null && modelItem.ExtensionPath != null && modelItem.ExtensionPath.length > extensionOffset) {
                            if (extensionPath.length < modelItem.ExtensionPath.length) {
                                extensionPath.push(modelItem.ExtensionPath[extensionOffset]);
                            }
                            viewItem.ExtensionPicked = modelItem.ExtensionPath[extensionOffset];
                        }
                    }
                    */
                    break;
                default:
                    break;
            }
        })
        return retVal;
    }
    
    setWatch(modelPath, viewPath) {
        
        /*
        // KLUDGE
        let modelPathLocal = [...modelPath];
        if (modelPath[0] === 'Advisors' && modelPath.length === 2 && viewPath.join('-') === 'Tracks-1-Home') {
            modelPathLocal[1] = prompt("Advisor Number: ");
        }
        if (modelPath[0] === 'Investors' && modelPath.length === 2 && viewPath.join('-') === 'Tracks-1-Home') {
            modelPathLocal[1] = prompt("Investor Number: ");
        }
        */
            
        setTimeout(() => {
            this.context.Controller.sendToServer(
                'Watch', 
                modelPath, 
                viewPath, 
                {}
            );
        });
    }
    
}

// For engine
//class ViewItemElem {

// For browser
export class ViewItemElem {
    constructor(context, label, viewSpec, modelPath, viewPath, crumbTrail) {
        this.context = context;
        this.contextHere = {
            ...this.context,
            ViewItemElem: this
        };
        this.label = label;
        this.viewSpec = viewSpec;
        this.modelPath = modelPath;
        this.viewPath = viewPath;
        this.crumbTrail = crumbTrail;
        switch(this.viewSpec.ElemType) {
            case 'Child':
                this.criteriaStatus = this.viewSpec.DefaultCriteriaStatus != null ? this.viewSpec.DefaultCriteriaStatus : 'Same';
                break;
            case 'Embedded':
                this.item = new ViewItem(this.contextHere, '1', this.viewSpec.Item, 
                    [...this.modelPath], 
                    [...this.viewPath, this.viewSpec.Label], 
                    [...this.crumbTrail], -1);
                break;
            case 'Extension':
                this.item = new ViewItem(this.contextHere, '1', this.viewSpec.Item, 
                    [...this.modelPath], 
                    [...this.viewPath, this.viewSpec.Label], 
                    [...this.crumbTrail], -1);
                break;
            default:
                break;
        }
    }
    
    updateFromModel(message) {
            console.log("ViewItemElem::updateFromModel - D: ", message);
        let retVal = true;
        switch(this.viewSpec.ElemType) {
            case 'Child':
                if (this.collection == null) {
                    this.collection = [];
                }
                message.forEach(modelItemCur => {
                    let viewSpecSub = this.viewSpec.Table != null ? this.viewSpec.Table : this.viewSpec;
                    let viewItemNew = new ViewItem(this.contextHere, modelItemCur.Key, viewSpecSub, 
                        this.ModelPath != null ? [...this.ModelPath, modelItemCur.Key] :  [...this.modelPath, modelItemCur.Key], 
                        this.ViewPath != null ? [...this.ViewPath, this.viewSpec.Label] : [...this.viewPath, this.viewSpec.Label], 
                        [...this.crumbTrail], -1);
                    let indexFound = this.collection.findIndex(cur => cur.key === modelItemCur.Key);
                    if (indexFound > -1) {
                        this.collection.splice(indexFound, 1, viewItemNew);
                    } else {
                        this.collection.push(viewItemNew);
                    }
                    viewItemNew.updateFromModel(modelItemCur);
                });
                let d = 0;
                break;
            default:
                break;
        }
        return retVal;
    }
    
    updateFromView(viewAction, modelPath, viewPath, model, view) {
        if (this.viewSpec.ElemType === 'Child') {
            if (this.criteriaStatus === 'Changed') {
                this.criteriaStatus = 'Same';
                this.setWatch(modelPath, viewPath);
            }
        }
    }
    
    setWatch(modelPath, viewPath) {
        setTimeout(() => {
            this.context.Controller.sendToServer(
                'Watch', 
                modelPath, 
                viewPath, 
                {}
            );
        });
    }
    
}

// For engine
//class ViewChild {

// For browser
export class ViewChild {
    constructor(context, label, viewSpec) {
        this.context = context;
        this.contextHere = {
            ...this.context,
            ViewChild: this
        };
        this.label = label;
        this.viewSpec = viewSpec;
    }
}

const accessLeafView = (nodeRootSpec, nodeCurSpec, nodeRootView, nodeCurView, pathTail) => {
    let retVal = nodeCurView;
    //let nodeCurSpecNext = nodeCurSpec;
    //let nodeCurViewNext = nodeCurView;
    if (nodeCurSpec.Alternatives != null) {
        //nodeCurSpecNext = nodeCurSpec.Alternatives[nodeCurView.AlternativeCur];
        //nodeCurViewNext = nodeCurView.Alternatives[nodeCurView.AlternativeCur];
        /*
        let alternativePickedLabel = pathTail.shift();
        nodeCurSpecNext = nodeCurSpec.Alternatives.findIndex(alternativeCur => alternativeCur.Label === alternativePickedLabel);
        if (nodeCurView.ViewSpec != null) {
            nodeCurView.ModelPath.pop();
            nodeCurView.ViewPath.pop();
        }
        nodeCurView.ViewSpec = nodeCurSpecNext;
        nodeCurView.ModelPath.push(nodeCurView.ViewSpec.Attr);
        nodeCurView.ViewPath.push(nodeCurView.ViewSpec.Label);
        */
    }
    /*
    if (pathTail.length > 0) {
        retVal = accessLeafView(nodeRootSpec, nodeCurSpecNext, nodeRootView, nodeCurViewNext, pathTail);
    }
    */
    return retVal;
}

const accessLeafModel = (nodeRootSpec, nodeCurSpec, nodeRootModel, nodeCurModel, pathTail) => {
    let retVal = nodeCurModel;
    if (pathTail.length > 0 && nodeCurSpec.Attrs != null && nodeCurSpec.Attrs[pathTail[0]] != null) {
        let pathTailSub;
        let pathSegCur = pathTail.splice(0, 1)[0];
        let nodeSubSpec = nodeCurSpec.Attrs[pathSegCur];
        switch (nodeSubSpec.Type) {
            case 'C':
                if (nodeCurModel.ChildAttrs == null) {
                    nodeCurModel.ChildAttrs = {};
                }
                if (nodeCurModel.ChildAttrs[pathSegCur] == null) {
                    nodeCurModel.ChildAttrs[pathSegCur] = {Items: []};
                }
                let nodeSubItems = nodeCurModel.ChildAttrs[pathSegCur].Items;
                if (pathTail.length > 0) {
                    let itemSub = nodeSubItems.find(cur => cur.Key === pathTail[0]);
                    if (itemSub != null) {
                        if (pathTail.length > 1) {
                            pathTailSub = [...pathTail];
                            pathTailSub.splice(0, 1);
                            retVal = accessLeafModel(nodeRootSpec, nodeSubSpec, nodeRootModel, itemSub, pathTailSub);
                        } else {
                            retVal = itemSub;
                        }
                    } else {
                        if (pathTail.length === 0) {
                            retVal = nodeSubItems;
                        } else {
                            if (pathTail.length === 1) {
                                nodeSubItems.push({Attrs: {}});
                                retVal = nodeSubItems[nodeSubItems.length-1];
                            } else {
                                retVal = [];
                            }
                        }
                    }
                } else {
                    retVal = nodeSubItems;
                }
                break;
            case 'E':
            case 'X':
                if (nodeCurModel.Attrs == null) {
                    nodeCurModel.Attrs = {};
                }
                if (nodeCurModel.Attrs[pathSegCur] == null) {
                    nodeCurModel.Attrs[pathSegCur] = {};
                }
                if (pathTail.length > 0) {
                    retVal = accessLeafModel(nodeRootSpec, nodeSubSpec, nodeRootModel, nodeCurModel.Attrs[pathSegCur], [...pathTail]);
                } else {
                    retVal = nodeCurModel.Attrs[pathSegCur];
                }
                break;
            case 'R':
                pathTailSub = [...nodeSubSpec.Reference];
                if (nodeSubSpec.Reference[0] === '/') {
                    pathTailSub.splice(0, 1);
                    nodeSubSpec = nodeRootSpec;
                    nodeCurModel = nodeRootModel;
                }
                if (pathTailSub.length > 0) {
                    retVal = accessLeafModel(nodeRootSpec, nodeSubSpec, nodeRootModel, nodeCurModel, pathTailSub);
                } else {
                    retVal = nodeCurModel;
                }
                break;
            default:
                break;
        }
    }
    return retVal;
}

const getExtendedModelAttr = (modelItem, modelAttrArray, extensionPath, extensionOffset) => {
    let retVal = null;
    if (modelItem != null) {
        let modelItemNested = modelItem;
        modelAttrArray.forEach((modelAttrCur) => {
            if (modelItemNested.Attrs != null && modelItemNested.Attrs[modelAttrCur] != null) {
                retVal = modelItemNested.Attrs[modelAttrCur];
                if (retVal.Type === 'E') {
                    modelItemNested = retVal;
                } else if (retVal.Type === 'X') {
                    modelItemNested = retVal;
                }
            }
        });
    }
    return retVal;
}

function reduxAlertFromController(state = {}, action) {
    let stateNew;
    switch (action.type) {
        case 'HANDLE_VIEW_RE_RENDER':
            stateNew = {...state};
            return stateNew;
        default:
            return state;
    }   
}

const reduxHandlers = combineReducers({
    reduxAlertFromController
});


// For engine
//module.exports = {Controller: Controller, SignInOrUp: SignInOrUp, Track: Track, ViewItem: ViewItem, ViewItemElem: ViewItemElem, ViewChild: ViewChild};




/*
const Controller_UpdateFromView = (controller, viewAction, modelPath, viewPath, model, view) => {
    let retVal = true;
    let trackCur = controller.tracks[viewPath[1]];
    switch(viewAction) {
        case 'AlternativePicked':
            if (Array.isArray(model)) {
                retVal = Child_UpdateFromView(controller, view, view.SpecElem, model, modelPath, 0, viewPath, 2);
            } else {
                if (view.AlternativePicked != null) {
                    if (view.Alternatives[view.AlternativePicked] != null) {
                        view.AlternativeCur = view.AlternativePicked;
                    } else {
                        let alternativePicked = view.ViewSpec.Alternatives[view.AlternativePicked];
                        if (alternativePicked.Label !== view.Alternatives[view.AlternativeCur].ViewSpec.Label) {
                            
                            let specSub = alternativePicked.Item.Elems[0];
                            
                            let viewLeafAlternative = Item_AddAlternative(view, view.AlternativePicked, view.Alternatives[view.AlternativeCur].CrumbTrail, [specSub.ModelAttr], [specSub.Label]);
                            setTimeout(() => {
                                controller.sendToServer(
                                    'Watch', 
                                    [...viewLeafAlternative.ModelPath], 
                                    [...viewLeafAlternative.ViewPath], 
                                    {});
                            });
                            retVal = Item_UpdateFromView(controller, viewLeafAlternative, viewLeafAlternative.ViewSpec.Item, view.Model, view.ModelPath, 0, view.ViewPath, 2);
                        }
                    }
                }
            }
            break;
        case 'CrumbPicked':
            break;
        case 'CrumbDropped':
            let viewItemNew = view.CrumbTrail[view.CrumbTrail.length-1].View;
            let specSub = viewItemNew.Alternatives[viewItemNew.AlternativeCur].ViewSpec.Drilldown != null ? viewItemNew.Alternatives[viewItemNew.AlternativeCur].ViewSpec.Drilldown : viewItemNew.Alternatives[viewItemNew.AlternativeCur].ViewSpec;
            retVal = Item_UpdateFromView(controller, viewItemNew.Alternatives[viewItemNew.AlternativeCur], specSub, model);
            break;
        case 'CriteriaChanged':
            break;
        default:
            break;
    }
    return retVal;
}


// From Model
const Item_UpdateFromModel = (controller, viewItem, specItem, modelItem, messageModel) => {
    let retVal = false;
    if (messageModel.Key != null) {
        retVal = true;
        modelItem.Key = messageModel.Key;
        if (modelItem.Attrs == null) {
            modelItem.Attrs = {};
            modelItem.ChildAttrs = {};
        } else {
            if (modelItem.ChildAttrs == null) {
                modelItem.ChildAttrs = {};
            }
        }
        if (messageModel.ExtensionPath != null) {
            modelItem.ExtensionPath = messageModel.ExtensionPath;
        }
        for (let attrCur in messageModel.Attrs) {
            modelItem.Attrs[attrCur] = messageModel.Attrs[attrCur];
        }
        if (messageModel.ChildAttrs != null && modelItem.ChildAttrs != null) {
            for (let attrCur in messageModel.ChildAttrs) {
                modelItem.ChildAttrs[attrCur] = messageModel.ChildAttrs[attrCur];
            }
        }
        let retCode = Item_NodeUpdateFromModel(controller, viewItem, specItem, modelItem, viewItem.ExtensionPath, 0);
        if (retCode === false) {
            retVal = false;
        }
    }
    return retVal;
}

const Item_NodeUpdateFromModel = (controller, viewItem, specItem, modelItem, extensionPath, extensionOffset) => {
    let retVal = true;
    specItem.Elems.forEach((specElemCur) => {
        let modelAttrArray = Array.isArray(specElemCur.ModelAttr) ? specElemCur.ModelAttr : [specElemCur.ModelAttr];
        let modelAttr = getExtendedModelAttr(modelItem, modelAttrArray, extensionPath, extensionOffset);
        let labelExtended = modelAttrArray.join('-') + '-' + specElemCur.Label;
        let viewElemCur = viewItem.Elems.find(cur => cur.LabelExtended === labelExtended);
        
        if (viewElemCur == null) {
            viewElemCur = {
                LabelExtended: labelExtended,
                LabelFlattened: specElemCur.Label,
                SpecElem: specElemCur,
                CrumbTrail: viewItem.CrumbTrail,
                Controller: controller
            }
            viewItem.Elems.push(viewElemCur);
        }
        switch (specElemCur.ElemType) {
            case 'Primitive':
                viewElemCur.Value = modelAttr != null && modelAttr.Value ? modelAttr.Value : '';
                break;
            case 'Constant':
                viewElemCur.Value = specElemCur.Value ? specElemCur.Value : '';
                break;
            case 'Reference':
                viewElemCur.ReferencePath = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [{Value: modelAttr.Value}] : [];
                let referencePathRaw = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [modelAttr.Value] : [];
                // KLUDGE
                if (referencePathRaw.length > 0) {
                    setTimeout(() => {
                        controller.sendToServer(
                            'Watch', 
                            ['Accounts', ...referencePathRaw], 
                            //['Tracks', 0, 'Home', 'Accounts'], 
                            [...viewItem.ViewPath],
                            {});

                    });
                    retVal = false;
                }
                break;
            case 'Embedded':
                viewElemCur.ExtensionPath = [];
                Item_NodeUpdateFromModel(controller, viewElemCur, specElemCur.Item, modelAttr, viewElemCur.ExtensionPath, 0);
                break;
            case 'Extension':
                Item_NodeUpdateFromModel(controller, viewElemCur, specElemCur.Item, modelAttr, extensionPath, extensionOffset+1);
                if (modelItem != null && modelItem.ExtensionPath != null && modelItem.ExtensionPath.length > extensionOffset) {
                    if (extensionPath.length < modelItem.ExtensionPath.length) {
                        extensionPath.push(modelItem.ExtensionPath[extensionOffset]);
                    }
                    viewItem.ExtensionPicked = modelItem.ExtensionPath[extensionOffset];
                }
                break;
            case 'Child':
                viewElemCur.ModelPath = [...viewItem.ModelPath, specElemCur.ModelAttr];
                viewElemCur.ViewPath = [...viewItem.ViewPath, specElemCur.Label];
                viewElemCur.Collection = [];
                viewElemCur.Watching = false;
                break;
            default:
                break;
        }
    })
    return retVal;
}

const Child_UpdateFromModel = (controller, viewChild, specChild, modelChild, messageModel, modelPathTip) => {
    let retVal = true;
    if (messageModel.ChildAttrs != null && messageModel.ChildAttrs[modelPathTip] != null) {
        modelChild.splice(0, modelChild.length, ...messageModel.ChildAttrs[modelPathTip].Items);
    } else {
        let indexFound;
        if (messageModel.Attrs != null && messageModel.Key != null) {
            indexFound = modelChild.findIndex(cur => cur.Key === messageModel.Key);
            if (indexFound > -1) {
                modelChild.splice(indexFound, 1, messageModel);
            } else {
                modelChild.push(messageModel);
            }
        } else {
            if (Array.isArray(messageModel)) {
                messageModel.forEach(messageCur => {
                    indexFound = modelChild.findIndex(cur => cur.Key === messageCur.Key);
                    if (indexFound > -1) {
                        modelChild.splice(indexFound, 1, messageCur);
                    } else {
                        modelChild.push(messageCur);
                    }
                });
            }
        }
    }
    viewChild.Collection = [];
    modelChild.forEach(modelItemCur => {
        let specSub1 = specChild.Table != null ? specChild.Table : specChild;
        let viewItemNew = Item_New(controller, modelItemCur.Key, specSub1, 0, viewChild.CrumbTrail, [...viewChild.ModelPath, modelItemCur.Key], [...viewChild.ViewPath, viewChild.SpecElem.Label], [], []);
        viewChild.Collection.push(viewItemNew);
        let specSub = viewItemNew.Alternatives[viewItemNew.AlternativeCur].ViewSpec.Table != null ? viewItemNew.Alternatives[viewItemNew.AlternativeCur].ViewSpec.Table : viewItemNew.Alternatives[viewItemNew.AlternativeCur].ViewSpec;

        // KLUDGE:
        if (specSub.ElemsSave != null) {
            specSub.Elems = [...specSub.ElemsSave];
            specSub.ElemsSave = null;
        }
        
        let retCode = Item_UpdateFromModel(controller, viewItemNew.Alternatives[viewItemNew.AlternativeCur], specSub, modelItemCur, modelItemCur);
        if (retCode === false) {
            retVal = false;
        }
    });
    return retVal;
}

// From View
const Item_UpdateFromView = (controller, viewItem, specItem, modelItem) => {
    let retVal = true;
    let retCode = Item_NodeUpdateFromView(controller, viewItem, specItem, modelItem, viewItem.ExtensionPath, 0);
    if (retCode === false) {
        retVal = false;
    }
    return retVal;
}

const Item_NodeUpdateFromView = (controller, viewItem, specItem, modelItem, extensionPath, extensionOffset) => {
    let retVal = true;
    specItem.Elems.forEach((specElemCur) => {
        let modelAttrArray = Array.isArray(specElemCur.ModelAttr) ? specElemCur.ModelAttr : [specElemCur.ModelAttr];
        let modelAttr = getExtendedModelAttr(modelItem, modelAttrArray, extensionPath, extensionOffset);
        let labelExtended = modelAttrArray.join('-') + '-' + specElemCur.Label;
        let viewElemCur = viewItem.Elems.find(cur => cur.LabelExtended === labelExtended);
        if (viewElemCur == null) {
            viewElemCur = {
                LabelExtended: labelExtended,
                LabelFlattened: specElemCur.Label,
                SpecElem: specElemCur,
                CrumbTrail: viewItem.CrumbTrail,
                Controller: controller
            }
            viewItem.Elems.push(viewElemCur);
        }
        switch (specElemCur.ElemType) {
            case 'Primitive':
                viewElemCur.Value = modelAttr != null && modelAttr.Value ? modelAttr.Value : '';
                break;
            case 'Constant':
                viewElemCur.Value = specElemCur.Value ? specElemCur.Value : '';
                break;
            case 'Reference':
                viewElemCur.ReferencePath = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [{Value: modelAttr.Value}] : [];
                let referencePathRaw = modelAttr != null && modelAttr.Value != null ? Array.isArray(modelAttr.Value) ? [...modelAttr.Value] :  [modelAttr.Value] : [];
                // KLUDGE
                if (referencePathRaw.length > 0) {
                    setTimeout(() => {
                        controller.sendToServer(
                            'Watch', 
                            ['Accounts', ...referencePathRaw], 
                            ['Tracks', 0, 'Home', 'Accounts'], 
                            {});

                    });
                    retVal = false;
                }
                break;
            case 'Embedded':
                viewElemCur.ExtensionPath = [];
                Item_NodeUpdateFromModel(controller, viewElemCur, specElemCur.Item, modelAttr, viewElemCur.ExtensionPath, 0);
                break;
            case 'Extension':
                Item_NodeUpdateFromModel(controller, viewElemCur, specElemCur.Item, modelAttr, extensionPath, extensionOffset+1);
                if (modelItem != null && modelItem.ExtensionPath != null && modelItem.ExtensionPath.length > extensionOffset) {
                    if (extensionPath.length < modelItem.ExtensionPath.length) {
                        extensionPath.push(modelItem.ExtensionPath[extensionOffset]);
                    }
                    viewItem.Alternatives[viewItem.AlternativeCur].ExtensionPicked = modelItem.ExtensionPath[extensionOffset];
                }
                break;
            case 'Child':
                viewElemCur.ModelPath = [...viewItem.ModelPath, specElemCur.ModelAttr];
                viewElemCur.ViewPath = [...viewItem.ViewPath, specElemCur.Label];
                viewElemCur.Collection = [];
                viewElemCur.Watching = false;
                break;
            default:
                break;
        }
    })
    return retVal;
}

const Child_UpdateFromView = (controller, viewChild, specItem, modelItem, modelPath, modelPathPos, viewPath, viewPathPos) => {
    let retVal = true;
    return retVal;
}

// Utility
const Item_New = (controller, key, viewSpec, alternativeCur, crumbTrail, modelPathBase, viewPathBase, modelPathTip, viewPathTip) => {
    let retVal = {
        Controller: controller,
        Key: key,
        ModelPathBase: [...modelPathBase],
        ViewPathBase: [...viewPathBase],
        ViewSpec: viewSpec,
        AlternativeCur: alternativeCur,
        Alternatives: []
    };
    for (let i = 0; i <= alternativeCur; i++) {
        if (i === alternativeCur) {
            retVal.Alternatives[i] = {
                ViewSpec: viewSpec.Alternatives != null ? viewSpec.Alternatives[alternativeCur] : viewSpec,
                ModelPath: [...retVal.ModelPathBase, ...modelPathTip],
                ViewPath: [...retVal.ViewPathBase, ...viewPathTip],
                ReferencePath: [],
                ExtensionPath: [],
                Elems: [],
                UpdateStatus: 'Base',
                Model: {},
                CrumbTrail: crumbTrail,
                Actions: {}
            };
        } else {
            retVal.Alternatives[i] = {};
        }
    }
    return retVal;
}

const Item_AddAlternative = (item, alternativeNew, crumbTrail, modelPathTip, viewPathTip) => {
    let retVal;
    for (let i = 0; i <= alternativeNew; i++) {
        if (i === alternativeNew) {
            retVal = item.Alternatives[i] = {
                ViewSpec: item.ViewSpec.Alternatives != null ? item.ViewSpec.Alternatives[alternativeNew] : item.ViewSpec,
                ModelPath: [...item.ModelPathBase, ...modelPathTip],
                ViewPath: [...item.ViewPathBase, ...viewPathTip],
                ReferencePath: [],
                ExtensionPath: [],
                Elems: [],
                UpdateStatus: 'Base',
                Model: {},
                CrumbTrail: crumbTrail,
                Actions: {}
            };
            item.AlternativeCur = alternativeNew;
        } else {
            if (item.Alternatives[i] == null) {
                item.Alternatives[i] = {};
            }
        }
    }
    return retVal;
}

*/



/*
const accessReferedModelPath = (nodeRoot, nodeCur, pathTail, pathTailOriginal) => {
    let retVal = null;
    let pathSegCur = pathTail.splice(0, 1);
    if (nodeCur.Attrs != null && nodeCur.Attrs[pathSegCur[0]] != null) {
        let nodeSub = nodeCur.Attrs[pathSegCur[0]];
        switch (nodeSub.Type) {
            case 'C':
                if (pathTail.length > 1) {
                    pathTail.splice(0, 1);
                    retVal = accessReferedModelPath(nodeRoot, nodeSub, pathTail, pathTailOriginal);
                }
                break;
            case 'R':
                let pathTemp = [...nodeSub.Reference];
                if (nodeSub.Reference[0] === '/') {
                    pathTemp.splice(0, 2);
                    retVal = [...pathTemp];
                } else if (nodeSub.Reference[0] === '.') {
                    pathTemp.splice(0, 2);
                    retVal = [...pathTailOriginal, ...pathTemp];
                } else {
                    pathTemp.splice(0, 1);
                    retVal = [...pathTailOriginal, ...pathTemp];
                }
                break;
            default:
                break;
        }
    }
    return retVal;
}
*/


    /*
    // The Model changed the View tree and is alerting all views to this fact
    modelToView(modelPath, viewPath, messageModel) {
        let retVal = true;
        console.log("modelPath: ", modelPath);
        console.log("viewPath: ", viewPath);
        console.log("messageModel: ", messageModel);
        console.log("rootView: ", this.rootView);
        if (viewPath.length > 0 && this.rootView[viewPath[0]] != null && this.rootView[viewPath[0]].UpdateFromModel != null) {
            retVal = this.rootView[viewPath[0]].UpdateFromModel(this, this.rootView[viewPath[0]], viewPath, 0, modelPath, 0, messageModel);
        }
        return retVal;
        */
        
        /*
        //console.log("MODEL - BEFORE: ", JSON.parse(JSON.stringify(this.rootModel)))
        this.leafModel = this.accessLeafModel([...this.modelPath]);
        if (Array.isArray(this.leafModel)) {
            //console.log("Array.isArray() ")
            let modelPathTip = this.modelPath[this.modelPath.length-1];
            let indexFound;
            if (messageModel.ChildAttrs != null && messageModel.ChildAttrs[modelPathTip] != null) {
                this.leafModel.splice(0, this.leafModel.length, ...messageModel.ChildAttrs[modelPathTip].Items);
            } else {
                if (messageModel.Attrs != null && messageModel.Key != null) {
                    indexFound = this.leafModel.findIndex(cur => cur.Key === messageModel.Key);
                    if (indexFound > -1) {
                        this.leafModel.splice(indexFound, 1, messageModel);
                    } else {
                        this.leafModel.push(messageModel);
                    }
                } else {
                    if (Array.isArray(messageModel)) {
                        messageModel.forEach(messageCur => {
                            indexFound = this.leafModel.findIndex(cur => cur.Key === messageCur.Key);
                            if (indexFound > -1) {
                                this.leafModel.splice(indexFound, 1, messageCur);
                            } else {
                                this.leafModel.push(messageCur);
                            }
                        });
                    }
                }
            }
        } else {
            if (messageModel.Key != null && this.leafModel != null) {
                //console.log("messageModel.Key: ", messageModel.Key)
                this.leafModel.Key = messageModel.Key;
                if (this.leafModel.Attrs == null) {
                    this.leafModel.Attrs = {};
                    this.leafModel.ChildAttrs = {};
                } else {
                    if (this.leafModel.ChildAttrs == null) {
                        this.leafModel.ChildAttrs = {};
                    }
                }
                if (messageModel.ExtensionPath != null) {
                    this.leafModel.ExtensionPath = messageModel.ExtensionPath;
                }
                for (let attrCur in messageModel.Attrs) {
                    this.leafModel.Attrs[attrCur] = messageModel.Attrs[attrCur];
                }
                if (messageModel.ChildAttrs != null && this.leafModel.ChildAttrs != null) {
                    for (let attrCur in messageModel.ChildAttrs) {
                        this.leafModel.ChildAttrs[attrCur] = messageModel.ChildAttrs[attrCur];
                    }
                }
            } else {
                console.log("action.Model.Key == null || action.Controller.leafModel == null", modelPathLocal);
            }
        }
        console.log("MODEL - AFTER: ",  JSON.parse(JSON.stringify(this.rootModel)))
        this.leafView = this.rootView;
        */
    //}

