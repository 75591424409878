const UIConfig = {
    HostName: 'portal.ihcfunds.com',
    HostNameForceDISABLED: 'localhost',     // Rename to HostNameForceDISABLED if localhost not desired
    WebsocketPort: '8601',
    UploadPort: '8701',
    AppId: 'IHC',
    Sentinel: ''
};

module.exports = {
    UIConfig: UIConfig
}
