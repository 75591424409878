const ControllerSpecBase = {
    ApiChannels: {
        Type: 'C',
        Attrs: {
            ChannelId: {
                Type: 'P'
            },
            Url: {
                Type: 'P'
            },
            Token: {
                Type: 'P'
            },
            SyncInterval: {
                Type: 'P'
            },
            SyncLastTimestamp: {
                Type: 'P'
            },
            SyncInstances: {
                Type: 'C',
                Attrs: {
                    SyncTimestamp: {
                        Type: 'P'
                    }
                }
            }
        }
    }
}

/* https://en.wikipedia.org/wiki/Cron

Each line of a crontab file represents a job, and looks like this:

# ┌───────────── minute (0 - 59)
# │ ┌───────────── hour (0 - 23)
# │ │ ┌───────────── day of the month (1 - 31)
# │ │ │ ┌───────────── month (1 - 12)
# │ │ │ │ ┌───────────── day of the week (0 - 6) (Sunday to Saturday;
# │ │ │ │ │                                   7 is also Sunday on some systems)
# │ │ │ │ │
# │ │ │ │ │
# * * * * * <command to execute>

The syntax of each line expects a cron expression made of five fields which represent the time to execute the command, followed by a shell command to execute.

While normally the job is executed when the time/date specification fields all match the current time and date, there is one exception: if both "day of month" (field 3) and "day of week" (field 5) are restricted (not "*"), then one or both must match the current day.[7]

For example, the following clears the Apache error log at one minute past midnight (00:01) every day, assuming that the default shell for the cron user is Bourne shell compliant:

1 0 * * * printf "" > /var/log/apache/error_log

This example runs a shell program called export_dump.sh at 23:45 (11:45 PM) every Saturday.

45 23 * * 6 /home/oracle/scripts/export_dump.sh

*/
// Note: It is also possible to specify */n to run for every n-th interval of time. Also, specifying multiple specific time intervals 
// can be done with commas (e.g., 1,2,3). The below would output "hello world" to the command line every 5th minute of 
// every first, second and third hour (i.e., 01:00, 01:05, 01:10, up until 03:55).
// */5 1,2,3 * * * echo hello world

/* Elem structure in view/ui
 * ItemForm - occurrence
 * - Stack
 *   = Breadcrumbs
 *   = StackFrames
 * = ItemSubforms - properties
 *   < Single
 *     < Component
 *       < Primitive
 *         - Input
 *       < File
 *       < Embedded
 *         - ItemForm
 *     < Reference
 *       - ItemForm
 *   < Multiple
 *     < Child - collection - list items
 *       - ItemForm
 *     < Extension
 *       - ItemForm
 *   < Annotation
 *   < Composite
 *     < Menu - parallel
 *       = Alternatives
 *         - ItemForm
 *       - MenuTarget
 *     < Group - serial
 *       = ItemForms
 *   < Action
 *
 */


const ControllerSpecIHC = {
    Engine: {
        ApiChannelInfo: {
            Name: 'Airtable',
            EndpointUrl: 'https://api.airtable.com',
            ApiKey: 'keyU5dbTZ4afARDMt',
            Base: 'apprG4I9ki3fulyXl'
        },
        Scheduling: {
            Frequency: 'hourly',
            Jobs: {
                EveryMinute: {
                    Minute: '*',
                    Hour: '*',
                    DayOfMonth: '*',
                    Month: '*',
                    DayOfWeek: '*',
                    Command: 'EveryMinute'
                }
            }
        },
        Mapping: [
        ],
        Indexing: [
            {
                Name: 'ActiveInstruments',
                ModelAttr: 'Instruments',
                ModelKey: 'InstrumentId',
                Attrs: {
                }
            }
        ],
        ProfileAlternatives: {
            SummaryEngines: {
                Alternatives: [
                ]
            }
        }
    },
    UI: {
        Logo: {
            img: '/IHC_Horizontal.jpg',
            width: '28em'
        },
        Splash: {
            img: '/IHC_Vertical.jpg',
            width: '50em'
        },
        Label: 'IHC Portal',
        ContactInfo: {
            Line1: 'Office: 855-298-2988',
            Line2: 'Email: investor@ihcfunds.com'
        },
        Masthead: {
            MastheadFontColor: '#3b5df2',
            MastheadBackgroundColor: '#ffffff',
            MarginTop: '0px'
        },
        MenuAlwaysOpen: true,
        CognitoRedirectURI: 'https://ihc-team.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=5cvte7mpad7n0qdfvvs2f9ut0h&redirect_uri=https://transitionportal.ihcfunds.com/',
        CognitoLogoutURI: 'https://ihc-team.auth.us-east-2.amazoncognito.com/logout?&client_id=5cvte7mpad7n0qdfvvs2f9ut0h&logout_uri=https://transitionportal.ihcfunds.com/',
        ProfileAlternatives: {
            Advisors: {
                AlternativeDefault: 'Home',
                MenuLocation: 'Top',
                Alternatives: [
                    {   Label: 'Home',
                        Attr: 'Advisors',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Name',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'Name',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Email',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'Email',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Phone',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'Phone',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Accounts',
                                    ElemType: 'Child',
                                    ModelAttr: 'ChildAccounts',
                                    Format: 'Table',
                                    DefaultCriteriaStatus: 'Changed',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Investor, Fund Name, Ownership Type, or Account Number ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Title',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Title',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 1
                                                }
                                            },
                                            {   Label: 'Fund(s)',
                                                ElemType: 'Reference',
                                                ModelAttr: 'Fund',
                                                Format: 'Input',
                                                Filter: {
                                                    DefaultSelections: [
                                                        {Id: '001', Value: 'Emerson Equity Bridge Fund I, LLC', Selected: true},
                                                        {Id: '101', Value: 'IHC - Capital Fund', Selected: true},
                                                        {Id: '102', Value: 'IHC - Income Fund 1', Selected: false, Memo: '(Closed)'},
                                                        {Id: '103', Value: 'IHC - Income Fund 2', Selected: true},
                                                        {Id: '104', Value: 'IHC - Ashbrook DST', Selected: true},
                                                        {Id: '105', Value: 'IHC - Peachtree DST', Selected: true},
                                                        {Id: '106', Value: 'IHC - Las Vegas DST', Selected: true},
                                                        {Id: '107', Value: 'IHC - Candle Light Cove DST', Selected: true},
                                                        {Id: '108', Value: 'IHC - Athens DST', Selected: true},
                                                        {Id: '109', Value: 'IHC - Income Fund 3', Selected: true},
                                                        {Id: '110', Value: 'IHC - Brookhaven DST', Selected: true},
                                                        {Id: '111', Value: 'IHC - Carson Valley DST', Selected: true},
                                                        {Id: '112', Value: 'IHC - Arlington Heights DST', Selected: true},
                                                        {Id: '113', Value: 'IHC - Reno DST', Selected: true},
                                                        {Id: '114', Value: 'IHC - Naperville DST', Selected: true},
                                                        {Id: '115', Value: 'IHC - Hamilton DST', Selected: true},
                                                        {Id: '116', Value: 'IHC - Appleton DST', Selected: true},
                                                        {Id: '117', Value: 'IHC - Chesterfield', Selected: true},
                                                        {Id: '118', Value: 'IHC - Lake Orion', Selected: true},
                                                        {Id: '119', Value: 'IHC - Largo', Selected: true},
                                                        {Id: '120', Value: 'IHC - Pinellas Park', Selected: true},
                                                        {Id: '121', Value: 'IHC - Dunedin', Selected: true},
                                                        {Id: '122', Value: 'IHC - Fort Myers', Selected: true},
                                                        {Id: '123', Value: 'IHC - Melbourne', Selected: true},
                                                        {Id: '124', Value: 'IHC - Grapevine', Selected: true},
														{Id: '125', Value: 'IHC - Augusta', Selected: true},
														{Id: '126', Value: 'IHC - Round Rock', Selected: true},
														{Id: '127', Value: 'IHC - Delray Beach', Selected: true},
														{Id: '128', Value: 'IHC - Creswell Development', Selected: true},
														{Id: '129', Value: 'IHC - St. Petersburg', Selected: true},
														{Id: '130', Value: 'IHC - Mequon', Selected: true},
														{Id: '131', Value: 'IHC - Fund 5', Selected: true},
														{Id: '132', Value: 'IHC - Eugene DST', Selected: true},
														{Id: '133', Value: 'IHC - North Haven DST', Selected: true},
														{Id: '134', Value: 'IHC - Winery Lane Development DST', Selected: true},
														{Id: '135', Value: 'IHC - Dartmouth DST', Selected: true},
														{Id: '136', Value: 'IHC - Fund 5 Notes', Selected: true},
														{Id: '137', Value: 'IHC - San Marcos DST', Selected: true},
														{Id: '138', Value: 'IHC - New Braunfels DST', Selected: true},
														{Id: '139', Value: 'IHC - Development Fund lll, LLC', Selected: true}
                                                    ]
                                                }
                                            },
                                            {   Label: 'Account Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountNumber',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Admit Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AdmitDate',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'desc',
                                                    Comparison: 'date',
                                                    Order: 3
                                                }
                                            },
                                            {   Label: 'Ownership Type',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'OwnershipType',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Capital Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TotalAmount',
                                                Sortable : {
                                                    Dir: 'desc',
                                                    Comparison: 'amount',
                                                    Order: 2
                                                },
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            }
                                            /*,
                                            {   Label: 'Investor Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'InvestorId',
                                                Format: 'Input'
                                            }
                                            */
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Title',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Title',
                                                    Disabled: true,
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Fund(s)',
                                                    ElemType: 'Reference',
                                                    ModelAttr: 'Fund',
                                                    Disabled: true,
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Account Number',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'AccountNumber',
                                                    Disabled: true,
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Admit Date',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'AdmitDate',
                                                    Disabled: true,
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Ownership Type',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'OwnershipType',
                                                    Disabled: true,
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Capital Amount',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'TotalAmount',
                                                    Disabled: true,
                                                    Format: 'Mask',
                                                    Mask: {
                                                        Type: 'Currency US'
                                                    }
                                                },
                                                {   Label: 'Statements',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'Statements',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Account Number',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'AccountNumber',
                                                                Format: 'Input',
                                                                Sortable : {
                                                                    Dir: 'asc',
                                                                    Comparison: 'string',
                                                                    Order: 1
                                                                }
                                                            },
                                                            {   Label: 'Fund',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Fund',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Period',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Mask',
                                                                Mask: {
                                                                    Type: 'Mon YYYY'
                                                                },
                                                                Sortable : {
                                                                    Dir: 'desc',
                                                                    Comparison: 'date',
                                                                    Order: 2
                                                                }
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'File'
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Confirmations',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'Confirmations',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Date',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'File'
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Tax Reports',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'TaxReports',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Year',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'File'
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Download Client Forms',
                        Attr: 'ClientForms',
                        Icon: 'file-pdf',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Address Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Investor Address Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Distribution Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Distribution Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Title Transfer',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Title Transfer.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Legal Name Change Request',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Legal Name Change Request.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Broker Dealer or Rep Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Broker Dealer or Rep Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Custodian Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Custodian Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Transfer on Death',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Transfer on Death.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Trustee Certification',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Trustee Certification.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Ownership Change Form',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Ownership Change Form.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Investor Contact Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Investor Contact Change.pdf',
                                    Format: 'File'
                                }
                            ]
                        }
                    }
                ]
            },
            AccountAccess: {
                AlternativeDefault: 'Home',
                MenuLocation: 'Top',
                Alternatives: [
                    {   Label: 'Home',
                        Attr: 'AccountAccess',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                /*
                                {   Label: 'Name',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'Name',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Mailing Address',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'Address',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Mailing City, State, Zip',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'CityStateZip',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Legal Address',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'LegalAddress',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Legal City State Zip',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'LegalCityStateZip',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Email',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'Email',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                {   Label: 'Phone',
                                    ElemType: 'Primitive',
                                    ModelAttr: 'Phone',
                                    Disabled: true,
                                    Format: 'Input'
                                },
                                */
                                {   Label: 'Accounts',
                                    ElemType: 'Child',
                                    ModelAttr: 'ChildAccounts',
                                    Format: 'Table',
                                    DefaultCriteriaStatus: 'Changed',
                                    Table: {
                                        Elems: [
                                            {   Label: 'Title',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Title',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Fund(s)',
                                                ElemType: 'Reference',
                                                ModelAttr: 'Fund',
                                                Format: 'Input',
                                                Filter: {
                                                    DefaultSelections: [
                                                        {Id: '001', Value: 'Emerson Equity Bridge Fund I, LLC', Selected: true},
                                                        {Id: '101', Value: 'IHC - Capital Fund', Selected: true},
                                                        {Id: '102', Value: 'IHC - Income Fund 1', Selected: false, Memo: '(Closed)'},
                                                        {Id: '103', Value: 'IHC - Income Fund 2', Selected: true},
                                                        {Id: '104', Value: 'IHC - Ashbrook DST', Selected: true},
                                                        {Id: '105', Value: 'IHC - Peachtree DST', Selected: true},
                                                        {Id: '106', Value: 'IHC - Las Vegas DST', Selected: true},
                                                        {Id: '107', Value: 'IHC - Candle Light Cove DST', Selected: true},
                                                        {Id: '108', Value: 'IHC - Athens DST', Selected: true},
                                                        {Id: '109', Value: 'IHC - Income Fund 3', Selected: true},
                                                        {Id: '110', Value: 'IHC - Brookhaven DST', Selected: true},
                                                        {Id: '111', Value: 'IHC - Carson Valley DST', Selected: true},
                                                        {Id: '112', Value: 'IHC - Arlington Heights DST', Selected: true},
                                                        {Id: '113', Value: 'IHC - Reno DST', Selected: true},
                                                        {Id: '114', Value: 'IHC - Naperville DST', Selected: true},
                                                        {Id: '115', Value: 'IHC - Hamilton DST', Selected: true},
                                                        {Id: '116', Value: 'IHC - Appleton DST', Selected: true},
                                                        {Id: '117', Value: 'IHC - Chesterfield', Selected: true},
                                                        {Id: '118', Value: 'IHC - Lake Orion', Selected: true},
                                                        {Id: '119', Value: 'IHC - Largo', Selected: true},
                                                        {Id: '120', Value: 'IHC - Pinellas Park', Selected: true},
                                                        {Id: '121', Value: 'IHC - Dunedin', Selected: true},
                                                        {Id: '122', Value: 'IHC - Fort Myers', Selected: true},
                                                        {Id: '123', Value: 'IHC - Melbourne', Selected: true},
                                                        {Id: '124', Value: 'IHC - Grapevine', Selected: true},
														{Id: '125', Value: 'IHC - Augusta', Selected: true},
														{Id: '126', Value: 'IHC - Round Rock', Selected: true},
														{Id: '127', Value: 'IHC - Delray Beach', Selected: true},
														{Id: '128', Value: 'IHC - Creswell Development', Selected: true},
														{Id: '129', Value: 'IHC - St. Petersburg', Selected: true},
														{Id: '130', Value: 'IHC - Mequon', Selected: true},
														{Id: '131', Value: 'IHC - Fund 5', Selected: true},
														{Id: '132', Value: 'IHC - Eugene DST', Selected: true},
														{Id: '133', Value: 'IHC - North Haven DST', Selected: true},
														{Id: '134', Value: 'IHC - Winery Lane Development DST', Selected: true},
														{Id: '135', Value: 'IHC - Dartmouth DST', Selected: true},
														{Id: '136', Value: 'IHC - Fund 5 Notes', Selected: true},
														{Id: '137', Value: 'IHC - San Marcos DST', Selected: true},
														{Id: '138', Value: 'IHC - New Braunfels DST', Selected: true},
														{Id: '139', Value: 'IHC - Development Fund lll, LLC', Selected: true}
                                                    ]
                                                }
                                            },
                                            {   Label: 'Account Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountNumber',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Admit Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AdmitDate',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Ownership Type',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'OwnershipType',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Capital Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TotalAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },

                                            {   Label: 'Address, Email, Phone',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Email',
                                                Disabled: true,
                                                Format: 'Input'
                                            } 
                                            /*,
                                            {   Label: 'Phone',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Phone',
                                                Disabled: true,
                                                Format: 'Input'
                                            },
                                            {   Label: 'Address',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Address',
                                                Format: 'Input'
                                            },
                                            {   Label: 'City, State, Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CityStateZip',
                                                Format: 'Input'
                                            } */

                                        ]
                                    }
                                },
                                {   Label: 'Statements',
                                    ElemType: 'Child',
                                    ModelAttr: 'Statements',
                                    Format: 'Table',
                                    DefaultCriteriaStatus: 'Changed',
                                    Table: {
                                        Elems: [
                                            {   Label: 'Account Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountNumber',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 1
                                                }
                                            },
                                            {   Label: 'Fund',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Fund',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Period',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DateText',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Mon YYYY'
                                                },
                                                Sortable : {
                                                    Dir: 'desc',
                                                    Comparison: 'date',
                                                    Order: 2
                                                }
                                            },
                                            {   Label: 'File',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'File',
                                                Format: 'File'
                                            }
                                        ]
                                    }
                                },
                                {   Label: 'Confirmations',
                                    ElemType: 'Child',
                                    ModelAttr: 'Confirmations',
                                    Format: 'Table',
                                    DefaultCriteriaStatus: 'Changed',
                                    Table: {
                                        Elems: [
                                            {   Label: 'Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DateText',
                                                Format: 'Input'
                                            },
                                            {   Label: 'File',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'File',
                                                Format: 'File'
                                            }
                                        ]
                                    }
                                },
                                {   Label: 'Tax Reports',
                                    ElemType: 'Child',
                                    ModelAttr: 'TaxReports',
                                    Format: 'Table',
                                    DefaultCriteriaStatus: 'Changed',
                                    Table: {
                                        Elems: [
                                            {   Label: 'Year',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DateText',
                                                Format: 'Input'
                                            },
                                            {   Label: 'File',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'File',
                                                Format: 'File'
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Download Client Forms',
                        Attr: 'ClientForms',
                        Icon: 'file-pdf',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Address Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Investor Address Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Distribution Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Distribution Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Title Transfer',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Title Transfer.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Legal Name Change Request',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Legal Name Change Request.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Broker Dealer or Rep Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Broker Dealer or Rep Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Custodian Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Custodian Change.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Transfer on Death',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Transfer on Death.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Trustee Certification',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Trustee Certification.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Ownership Change Form',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Ownership Change Form.pdf',
                                    Format: 'File'
                                },
                                {   Label: 'Investor Contact Change',
                                    ElemType: 'Constant',
                                    Value: 'IHC - Investor Contact Change.pdf',
                                    Format: 'File'
                                }
                            ]
                        }
                    }
                ]
            },
            Administrators: {
                AlternativeDefault: 'Funds',
                MenuLocation: 'Left',
                Alternatives: [
                    {   Label: 'Funds',
                        Attr: 'Funds',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Funds',
                                    ElemType: 'Child',
                                    ModelAttr: 'Funds',
                                    Format: 'Table',
                                    Table: {
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Id',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Name',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Payment Period',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PaymentPeriod',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Distribution Day',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DistributionDay',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Status',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Status',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Short',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Short',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Type',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Type',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Company Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CompanyId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Term',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Term',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Distribution',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Distribution',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Notes',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Notes',
                                                Format: 'Input'
                                            },
                                            {   Label: 'First Admit Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'FirstAdmitDate',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Id',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Name',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Payment Period',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'PaymentPeriod',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Distribution Day',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'DistributionDay',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Status',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Status',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Short',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Short',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Type',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Type',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Company Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CompanyId',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Term',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Term',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Distribution',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Distribution',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Notes',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Notes',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'First Admit Date',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'FirstAdmitDate',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    /*
                    {   Label: 'Investors',
                        Attr: 'Investors',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Investors',
                                    ElemType: 'Child',
                                    ModelAttr: 'Investors',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Name, Email, Phone, Address ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Investor Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'InvestorId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Name',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Mailing Address',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Address',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Mailing City State Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CityStateZip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Legal Address',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'LegalAddress',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Legal City State Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'LegalCityStateZip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Email',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Email',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Phone',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Phone',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Investor Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'InvestorId',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Name',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Mailing Address',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Address',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Mailing City State Zip',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CityStateZip',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Legal Address',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'LegalAddress',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Legal City State Zip',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'LegalCityStateZip',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Email',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Email',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Phone',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Phone',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    */
                    {   Label: 'Accounts',
                        Attr: 'Accounts',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Accounts',
                                    ElemType: 'Child',
                                    ModelAttr: 'Accounts',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Investor, Fund Name, Account Number, etc. ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Title',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Title',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Fund(s)',
                                                ElemType: 'Reference',
                                                ModelAttr: 'Fund',
                                                Format: 'Input',
                                                Filter: {
                                                    DefaultSelections: [
                                                        {Id: '001', Value: 'Emerson Equity Bridge Fund I, LLC', Selected: true},
                                                        {Id: '101', Value: 'IHC - Capital Fund', Selected: true},
                                                        {Id: '102', Value: 'IHC - Income Fund 1', Selected: false, Memo: '(Closed)'},
                                                        {Id: '103', Value: 'IHC - Income Fund 2', Selected: true},
                                                        {Id: '104', Value: 'IHC - Ashbrook DST', Selected: true},
                                                        {Id: '105', Value: 'IHC - Peachtree DST', Selected: true},
                                                        {Id: '106', Value: 'IHC - Las Vegas DST', Selected: true},
                                                        {Id: '107', Value: 'IHC - Candle Light Cove DST', Selected: true},
                                                        {Id: '108', Value: 'IHC - Athens DST', Selected: true},
                                                        {Id: '109', Value: 'IHC - Income Fund 3', Selected: true},
                                                        {Id: '110', Value: 'IHC - Brookhaven DST', Selected: true},
                                                        {Id: '111', Value: 'IHC - Carson Valley DST', Selected: true},
                                                        {Id: '112', Value: 'IHC - Arlington Heights DST', Selected: true},
                                                        {Id: '113', Value: 'IHC - Reno DST', Selected: true},
                                                        {Id: '114', Value: 'IHC - Naperville DST', Selected: true},
                                                        {Id: '115', Value: 'IHC - Hamilton DST', Selected: true},
                                                        {Id: '116', Value: 'IHC - Appleton DST', Selected: true},
                                                        {Id: '117', Value: 'IHC - Chesterfield', Selected: true},
                                                        {Id: '118', Value: 'IHC - Lake Orion', Selected: true},
                                                        {Id: '119', Value: 'IHC - Largo', Selected: true},
                                                        {Id: '120', Value: 'IHC - Pinellas Park', Selected: true},
                                                        {Id: '121', Value: 'IHC - Dunedin', Selected: true},
                                                        {Id: '122', Value: 'IHC - Fort Myers', Selected: true},
                                                        {Id: '123', Value: 'IHC - Melbourne', Selected: true},
                                                        {Id: '124', Value: 'IHC - Grapevine', Selected: true},
														{Id: '125', Value: 'IHC - Augusta', Selected: true},
														{Id: '126', Value: 'IHC - Round Rock', Selected: true},
														{Id: '127', Value: 'IHC - Delray Beach', Selected: true},
														{Id: '128', Value: 'IHC - Creswell Development', Selected: true},
														{Id: '129', Value: 'IHC - St. Petersburg', Selected: true},
														{Id: '130', Value: 'IHC - Mequon', Selected: true},
														{Id: '131', Value: 'IHC - Fund 5', Selected: true},
														{Id: '132', Value: 'IHC - Eugene DST', Selected: true},
														{Id: '133', Value: 'IHC - North Haven DST', Selected: true},
														{Id: '134', Value: 'IHC - Winery Lane Development DST', Selected: true},
														{Id: '135', Value: 'IHC - Dartmouth DST', Selected: true},
														{Id: '136', Value: 'IHC - Fund 5 Notes', Selected: true},
														{Id: '137', Value: 'IHC - San Marcos DST', Selected: true},
														{Id: '138', Value: 'IHC - New Braunfels DST', Selected: true},
														{Id: '139', Value: 'IHC - Development Fund lll, LLC', Selected: true}
                                                    ]
                                                }
                                            },
                                            {   Label: 'Account Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountNumber',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 1
                                                }
                                            },
                                            {   Label: 'Admit Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AdmitDate',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'desc',
                                                    Comparison: 'date',
                                                    Order: 2
                                                }
                                            },
                                            {   Label: 'Is Active',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'IsActive',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Capital Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TotalAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Equity Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'EquityAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Premium Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PremiumAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Number Of Units',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'NumberOfUnits',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Number',
                                                    Decimals: 4
                                                }
                                            },
                                            {   Label: 'Commission Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CommissionAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Broker Dealer Fee (1%)',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BrokerDealerFee',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Broker Dealer Reallowance (1%)',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BrokerDealerReallowance',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Wholesaler Fee (1%)',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'WholesalerFee',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Ownership Type',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'OwnershipType',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 3
                                                }
                                            },
                                            {   Label: 'Tax Qualified',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TaxQualified',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 3
                                                }
                                            },
                                            {   Label: 'IRS Tax Id 1',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'IRSTaxId1',
                                                Format: 'Input'
                                            },
                                            {   Label: 'IRS Tax Id 2',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'IRSTaxId2',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Rep Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'RepName1',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 3
                                                }
                                            },
                                            {   Label: 'Rep Phone',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PhoneNum',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Rep Email',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Email',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Bkr Dlr Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BkrDlrName',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 3
                                                }
                                            },
                                            {   Label: 'ABA',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DFI_NUM_WITH_CHECK',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Bank Account',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DFI_ACC_NUM',
                                                Format: 'Input'
                                            },

                                            {   Label: 'Notes and NIGO',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Notes',
                                                Format: 'Input'
                                            },

                                            {   Label: 'Investor Address',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Address',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Address Line 2',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Address2',
                                                Format: 'Input'
                                            },
                                            {   Label: 'City, State, Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CityStateZip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Investor Phone',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Phone',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Investor Email',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'InvestorEmail',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Investor DOB',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DOB',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Custodian',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CUSTODIAN_NUM',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Title',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Title',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Fund',
                                                    ElemType: 'Reference',
                                                    ModelAttr: 'Fund',
                                                    ModelAttrKey: 'Id',
                                                    ModelAttrName: 'Name',
                                                    Item: {
                                                        ModelAction: 'Update',
                                                        Elems: [
                                                            {   Label: 'Funds',
                                                                ElemType: 'Child',
                                                                ModelAttr: 'Funds',
                                                                Format: 'FindAndUse',
                                                                KeySource: 'Criterion',
                                                                FindAndUse: {
                                                                    Item: {
                                                                        ModelAction: 'Watch',
                                                                        Elems: [
                                                                            {   Label: 'Id',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Id',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Name',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Name',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Period',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Period',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Day',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Day',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Status',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Status',
                                                                                Format: 'Input'
                                                                            }
                                                                        ]
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Submit Date',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'SubmittedDate',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Ownership Type',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'OwnershipType',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Tax Qualified',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'TaxQualified',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Notes and NIGO',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Notes',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'IGO',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'IGO',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Deposit Date',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'DepositDate',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Deposit Type',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'DepositType',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Admit Date',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'AdmitDate',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Is Active',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'IsActive',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'IRS Tax Id 1',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'IRSTaxId1',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'IRS Tax Id 2',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'IRSTaxId2',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Account Number',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'AccountNumber',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Equity Amount',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'EquityAmount',
                                                    Format: 'Mask',
                                                    Mask: {
                                                        Type: 'Currency US'
                                                    }
                                                },
                                                {   Label: 'Premium Amount',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'PremiumAmount',
                                                    Format: 'Mask',
                                                    Mask: {
                                                        Type: 'Currency US'
                                                    }
                                                },
                                                {   Label: 'Number of Units',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'NumberOfUnits',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Commission Amount',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CommissionAmount',
                                                    Format: 'Mask',
                                                    Mask: {
                                                        Type: 'Currency US'
                                                    }
                                                },
                                                {   Label: 'Broker Dealer Fee (1%)',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BrokerDealerFee',
                                                    Format: 'Mask',
                                                    Mask: {
                                                        Type: 'Currency US'
                                                    }
                                                },
                                                {   Label: 'Broker Dealer Reallowance (1%)',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BrokerDealerReallowance',
                                                    Format: 'Mask',
                                                    Mask: {
                                                        Type: 'Currency US'
                                                    }
                                                },
                                                {   Label: 'Wholesaler Fee (1%)',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'WholesalerFee',
                                                    Format: 'Mask',
                                                    Mask: {
                                                        Type: 'Currency US'
                                                    }
                                                },
                                                {   Label: 'Advisor',
                                                    ElemType: 'Reference',
                                                    ModelAttr: 'AdvisorId',
                                                    ModelAttrKey: 'AdvisorId',
                                                    ModelAttrName: 'Name',
                                                    Item: {
                                                        ModelAction: 'Update',
                                                        Elems: [
                                                            {   Label: 'Advisor',
                                                                ElemType: 'Child',
                                                                ModelAttr: 'Advisors',
                                                                Format: 'FindAndUse',
                                                                KeySource: 'Criterion',
                                                                FindAndUse: {
                                                                    Item: {
                                                                        ModelAction: 'Watch',
                                                                        Elems: [
                                                                            {   Label: 'AdvisorId',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'AdvisorId',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Name',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Name',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Phone',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Phone',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Email',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Email',
                                                                                Format: 'Input'
                                                                            }
                                                                        ]
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Investor',
                                                    ElemType: 'Reference',
                                                    ModelAttr: 'InvestorId',
                                                    ModelAttrKey: 'InvestorId',
                                                    ModelAttrName: 'Name',
                                                    Item: {
                                                        ModelAction: 'Update',
                                                        Elems: [
                                                            {   Label: 'Investor',
                                                                ElemType: 'Child',
                                                                ModelAttr: 'Investors',
                                                                Format: 'FindAndUse',
                                                                KeySource: 'Criterion',
                                                                FindAndUse: {
                                                                    Item: {
                                                                        ModelAction: 'Watch',
                                                                        Elems: [
                                                                            {   Label: 'InvestorId',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'InvestorId',
                                                                                Format: 'Input'
                                                                            },
                                                                            {   Label: 'Name',
                                                                                ElemType: 'Primitive',
                                                                                ModelAttr: 'Name',
                                                                                Format: 'Input'
                                                                            }
                                                                        ]
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Payment Instructions',
                                                    ElemType: 'Embedded',
                                                    ModelAttr: 'PaymentInstructions',
                                                    Item: {
                                                        ModelAction: 'Update',
                                                        Elems: [
                                                            {   Label: 'Custodial',
                                                                ElemType: 'Extension',
                                                                ModelAttr: 'Custodial',
                                                                ShowRadioButton: true,
                                                                Format: 'Item',
                                                                Item: {
                                                                    Elems: [
                                                                        {   Label: 'Custodian Name',
                                                                            ElemType: 'Reference',
                                                                            ModelAttr: 'Custodian',
                                                                            ModelAttrKey: 'Id',
                                                                            ModelAttrName: 'Name',
                                                                            Item: {
                                                                                ModelAction: 'Update',
                                                                                Elems: [
                                                                                    {   Label: 'Custodians',
                                                                                        ElemType: 'Child',
                                                                                        ModelAttr: 'Custodians',
                                                                                        Format: 'FindAndUse',
                                                                                        KeySource: 'Criterion',
                                                                                        FindAndUse: {
                                                                                            Item: {
                                                                                                ModelAction: 'Watch',
                                                                                                Elems: [
                                                                                                    {   Label: 'Id',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'Id',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Name',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'Name',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'ABA Number',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'ABANumber',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Custodian Account #',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'CustodianAccountNumber',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Check Payable',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'CheckPayable',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Payee Address Line 1',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'PayeeAddressLine1',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Payee Address Line 2',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'PayeeAddressLine2',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Payee City, State, Zip',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'PayeeCityStateZip',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Tax Id',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'TaxId',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Email',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'Email',
                                                                                                        Format: 'Input'
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                        },
                                                                        {   Label: 'Custodian Account #',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'CustodianAccountNumber',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Pay To Custodian',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'PayToCustodian',
                                                                            Format: 'Input'
                                                                        }
                                                                    ]
                                                                }
                                                            },
                                                            {   Label: 'ACH',
                                                                ElemType: 'Extension',
                                                                ModelAttr: 'ACH',
                                                                ShowRadioButton: true,
                                                                Format: 'Item',
                                                                Item: {
                                                                    Elems: [
                                                                        {   Label: 'ABA Number',
                                                                            ElemType: 'Reference',
                                                                            ModelAttr: 'ABANumber',
                                                                            ModelAttrKey: 'ABANumber',
                                                                            ModelAttrName: 'BankName',
                                                                            Item: {
                                                                                ModelAction: 'Update',
                                                                                Elems: [
                                                                                    {   Label: 'ABA Numbers',
                                                                                        ElemType: 'Child',
                                                                                        ModelAttr: 'ABANumbers',
                                                                                        Format: 'FindAndUse',
                                                                                        KeySource: 'Criterion',
                                                                                        FindAndUse: {
                                                                                            Item: {
                                                                                                ModelAction: 'Watch',
                                                                                                Elems: [
                                                                                                    {   Label: 'ABA Number',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'Id',
                                                                                                        Format: 'Input'
                                                                                                    },
                                                                                                    {   Label: 'Bank Name',
                                                                                                        ElemType: 'Primitive',
                                                                                                        ModelAttr: 'BankName',
                                                                                                        Format: 'Input'
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                            
                                                                            
                                                                        },
                                                                        {   Label: 'Account Number',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'AccountNumber',
                                                                            Format: 'Input'
                                                                        }
                                                                    ]
                                                                }
                                                            },
                                                            {   Label: 'Check',
                                                                ElemType: 'Extension',
                                                                ModelAttr: 'Check',
                                                                ShowRadioButton: true,
                                                                Format: 'Item',
                                                                Item: {
                                                                    Elems: [
                                                                        {   Label: 'Check Payable',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'CheckPayable',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Payee Address Line 1',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'PayeeAddressLine1',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Payee Address Line 2',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'PayeeAddressLine2',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Payee: City, State, Zip',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'PayeeCityStateZip',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Memo',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'Memo',
                                                                            Format: 'Input'
                                                                        }
                                                                    ]
                                                                }
                                                            },
                                                            {   Label: 'Wire',
                                                                ElemType: 'Extension',
                                                                ModelAttr: 'Wire',
                                                                ShowRadioButton: true,
                                                                Format: 'Item',
                                                                Item: {
                                                                    Elems: [
                                                                        {   Label: 'Bank Name',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'BankName',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'SWIFT/ABA Number',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'SWIFTABANumber',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Custodian Account #',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'CustodianAccountNumber',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Payee Account #',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'PayeeAccountNumber',
                                                                            Format: 'Input'
                                                                        },
                                                                        {   Label: 'Memo/FFC',
                                                                            ElemType: 'Primitive',
                                                                            ModelAttr: 'MemoFFC',
                                                                            Format: 'Input'
                                                                        }
                                                                    ]
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Statements',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'Statements',
                                                    Format: 'Table',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Period',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Mask',
                                                                Mask: {
                                                                    Type: 'Mon YYYY'
                                                                },
                                                                Sortable : {
                                                                    Dir: 'desc',
                                                                    Comparison: 'date'
                                                                }
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'Input'
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Confirmations',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'Confirmations',
                                                    Format: 'Table',
                                                    Insertable: true,
                                                    InsertLabel: 'Add New Confirmation',
                                                    KeySource: 'UUID',
                                                    InsertForm: {
                                                        Elems: [
                                                            {   Label: 'Date',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'Upload'
                                                            }
                                                        ],
                                                        SaveButtonLabel: 'Save',
                                                        CancelButtonLabel: 'Cancel'
                                                    },
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Date',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'Input'
                                                            }
                                                        ],
                                                        Drilldown: {
                                                            Elems: [
                                                                {   Label: 'Date',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'DateText',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'File',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'File',
                                                                    Format: 'Input'
                                                                }
                                                            ]
                                                        }
                                                    }
                                                },
                                                {   Label: 'Tax Reports',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'TaxReports',
                                                    Format: 'Table',
                                                    Insertable: true,
                                                    InsertLabel: 'Add New Tax Report',
                                                    KeySource: 'UUID',
                                                    InsertForm: {
                                                        Elems: [
                                                            {   Label: 'Year',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'Upload'
                                                            }
                                                        ],
                                                        SaveButtonLabel: 'Save',
                                                        CancelButtonLabel: 'Cancel'
                                                    },
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Year',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'Input'
                                                            }
                                                        ],
                                                        Drilldown: {
                                                            Elems: [
                                                                {   Label: 'Year',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'DateText',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'File',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'File',
                                                                    Format: 'Input'
                                                                }
                                                            ]
                                                        }
                                                    }
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Account Access',
                        Attr: 'AccountAccess',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Account Access',
                                    ElemType: 'Child',
                                    ModelAttr: 'AccountAccess',
                                    Format: 'Table',
                                    /*
                                    Insertable: false,
                                    InsertLabel: 'Add New Account Access',
                                    KeySource: 'UUID',
                                    InsertForm: {
                                        Elems: [
                                            {   Label: 'Email',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Email',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Account Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountNumber',
                                                Format: 'Input'
                                            }
                                        ],
                                        SaveButtonLabel: 'Save',
                                        CancelButtonLabel: 'Cancel'
                                    },
                                    */
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Email or Account'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Email',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Email',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Email',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Email',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Accounts',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'ChildAccounts',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Title',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Title',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Fund(s)',
                                                                ElemType: 'Reference',
                                                                ModelAttr: 'Fund',
                                                                Format: 'Input',
                                                                Filter: {
                                                                    DefaultSelections: [
																		{Id: '001', Value: 'Emerson Equity Bridge Fund I, LLC', Selected: true},
                                                                        {Id: '101', Value: 'IHC - Capital Fund', Selected: true},
                                                                        {Id: '102', Value: 'IHC - Income Fund 1', Selected: false, Memo: '(Closed)'},
                                                                        {Id: '103', Value: 'IHC - Income Fund 2', Selected: true},
                                                                        {Id: '104', Value: 'IHC - Ashbrook DST', Selected: true},
                                                                        {Id: '105', Value: 'IHC - Peachtree DST', Selected: true},
                                                                        {Id: '106', Value: 'IHC - Las Vegas DST', Selected: true},
                                                                        {Id: '107', Value: 'IHC - Candle Light Cove DST', Selected: true},
                                                                        {Id: '108', Value: 'IHC - Athens DST', Selected: true},
                                                                        {Id: '109', Value: 'IHC - Income Fund 3', Selected: true},
                                                                        {Id: '110', Value: 'IHC - Brookhaven DST', Selected: true},
                                                                        {Id: '111', Value: 'IHC - Carson Valley DST', Selected: true},
                                                                        {Id: '112', Value: 'IHC - Arlington Heights DST', Selected: true},
                                                                        {Id: '113', Value: 'IHC - Reno DST', Selected: true},
                                                                        {Id: '114', Value: 'IHC - Naperville DST', Selected: true},
                                                                        {Id: '115', Value: 'IHC - Hamilton DST', Selected: true},
                                                                        {Id: '116', Value: 'IHC - Appleton DST', Selected: true},
                                                                        {Id: '117', Value: 'IHC - Chesterfield', Selected: true},
                                                                        {Id: '118', Value: 'IHC - Lake Orion', Selected: true},
                                                                        {Id: '119', Value: 'IHC - Largo', Selected: true},
                                                                        {Id: '120', Value: 'IHC - Pinellas Park', Selected: true},
                                                                        {Id: '121', Value: 'IHC - Dunedin', Selected: true},
                                                                        {Id: '122', Value: 'IHC - Fort Myers', Selected: true},
                                                                        {Id: '123', Value: 'IHC - Melbourne', Selected: true},
																		{Id: '124', Value: 'IHC - Grapevine', Selected: true},
																		{Id: '125', Value: 'IHC - Augusta', Selected: true},
																		{Id: '126', Value: 'IHC - Round Rock', Selected: true},
																		{Id: '127', Value: 'IHC - Delray Beach', Selected: true},
																		{Id: '128', Value: 'IHC - Creswell Development', Selected: true},
																		{Id: '129', Value: 'IHC - St. Petersburg', Selected: true},
																		{Id: '130', Value: 'IHC - Mequon', Selected: true},
																		{Id: '131', Value: 'IHC - Fund 5', Selected: true},
																		{Id: '132', Value: 'IHC - Eugene DST', Selected: true},
																		{Id: '133', Value: 'IHC - North Haven DST', Selected: true},
																		{Id: '134', Value: 'IHC - Winery Lane Development DST', Selected: true},
																		{Id: '135', Value: 'IHC - Dartmouth DST', Selected: true},
																		{Id: '136', Value: 'IHC - Fund 5 Notes', Selected: true},
																		{Id: '137', Value: 'IHC - San Marcos DST', Selected: true},
																		{Id: '138', Value: 'IHC - New Braunfels DST', Selected: true},
																		{Id: '139', Value: 'IHC - Development Fund lll, LLC', Selected: true}
                                                                    ]
                                                                }
                                                            },
                                                            {   Label: 'Account Number',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'AccountNumber',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Admit Date',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'AdmitDate',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Ownership Type',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'OwnershipType',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Capital Amount',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'TotalAmount',
                                                                Format: 'Mask',
                                                                Mask: {
                                                                    Type: 'Currency US'
                                                                }
                                                            },
                
                                                            {   Label: 'Address, Email, Phone',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Email',
                                                                Disabled: true,
                                                                Format: 'Input'
                                                            } 
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Statements',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'Statements',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Account Number',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'AccountNumber',
                                                                Format: 'Input',
                                                                Sortable : {
                                                                    Dir: 'asc',
                                                                    Comparison: 'string',
                                                                    Order: 1
                                                                }
                                                            },
                                                            {   Label: 'Fund',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Fund',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Period',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Mask',
                                                                Mask: {
                                                                    Type: 'Mon YYYY'
                                                                },
                                                                Sortable : {
                                                                    Dir: 'desc',
                                                                    Comparison: 'date',
                                                                    Order: 2
                                                                }
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'File'
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Confirmations',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'Confirmations',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Date',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'File'
                                                            }
                                                        ]
                                                    }
                                                },
                                                {   Label: 'Tax Reports',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'TaxReports',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Year',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'DateText',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'File',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'File',
                                                                Format: 'File'
                                                            }
                                                        ]
                                                    }
                                                }
                                            
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                        
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'ABA Numbers',
                        Attr: 'ABANumbers',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'ABA Numbers',
                                    ElemType: 'Child',
                                    ModelAttr: 'ABANumbers',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Bank Name, ABA Number, etc. ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'ABA Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'ABANumber',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Bank Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BankName',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'ABA Number',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'ABANumber',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Bank Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BankName',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Custodians',
                        Attr: 'Custodians',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Custodians',
                                    ElemType: 'Child',
                                    ModelAttr: 'Custodians',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Custodian Name, Account Number, etc. ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Id',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Name',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Custodian Notes',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CustodianNotes',
                                                Format: 'Input'
                                            },
                                            {   Label: 'ABA Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'ABANumber',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Custodian Account #',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CustodianAccountNumber',
                                                Format: 'Input'
                                            },
                                            {   Label: 'ACH or Wire',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'ACHOrWire',
                                                Format: 'Input'
                                            },
                                            {   Label: 'FFC Account #',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'FFCAccountNumber',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Check Payable',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CheckPayable',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Payee Address Line 1',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PayeeAddressLine1',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Payee Address Line 2',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PayeeAddressLine2',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Payee City, State, Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PayeeCityStateZip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Tax Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TaxId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Phone',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Phone',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Email',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Email',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Id',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Name',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Custodian Notes',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CustodianNotes',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'ABA Number',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'ABANumber',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Custodian Account #',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CustodianAccountNumber',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'FFC Account #',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'FFCAccountNumber',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Check Payable',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CheckPayable',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Payee Address Line 1',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'PayeeAddressLine1',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Payee Address Line 2',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'PayeeAddressLine2',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Payee City, State, Zip',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'PayeeCityStateZip',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Tax Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'TaxId',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Phone',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Phone',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Email',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Email',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Advisors',
                        Attr: 'Advisors',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Advisors',
                                    ElemType: 'Child',
                                    ModelAttr: 'Advisors',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Name, Email, Phone ... '
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Advisor Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AdvisorId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Name',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Rep 2 Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Name2',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Address 1',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Address1',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Address 2',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Address2',
                                                Format: 'Input'
                                            },
                                            {   Label: 'City',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'City',
                                                Format: 'Input'
                                            },
                                            {   Label: 'State',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'State',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Zip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'City State, and Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CityStateZip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Broker Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BrokerId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Broker Office Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BrokerOfficeId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Broker Dealer Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BkrDlrName',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Email',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Email',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Phone',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Phone',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Advisor Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'AdvisorId',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Name',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Rep 2 Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Name2',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Address 1',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Address1',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Address 2',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Address2',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'City',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'City',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'State',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'State',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Zip',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Zip',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'City State, and Zip',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CityStateZip',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Broker Number',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BrokerId',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Broker Office Number',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BrokerOfficeId',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Broker Dealer Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BkrDlrName',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Email',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Email',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Phone',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Phone',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Broker Dealers',
                        Attr: 'Brokers',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Broker Dealers',
                                    ElemType: 'Child',
                                    ModelAttr: 'Brokers',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Name, etc. ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Id',
                                                Format: 'Input'
                                            },
                                            {   Label: 'CRD',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'CRD',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Brokerage Firm Name',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BrokerageFirmName',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Home Office Address',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'HomeOfficeAddress',
                                                Format: 'Input'
                                            },
                                            {   Label: 'City',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'City',
                                                Format: 'Input'
                                            },
                                            {   Label: 'State',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'State',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Zip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'BD_Or_RIA',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'BD_Or_RIA',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Notes',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Notes',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Id',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'CRD',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'CRD',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Brokerage Firm Name',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BrokerageFirmName',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Home Office Address',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'HomeOfficeAddress',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'City',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'City',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'State',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'State',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Zip',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Zip',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'BD_Or_RIA',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'BD_Or_RIA',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Notes',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Notes',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Offices',
                                                    ElemType: 'Child',
                                                    ModelAttr: 'Offices',
                                                    Format: 'Table',
                                                    DefaultCriteriaStatus: 'Changed',
                                                    Insertable: true,
                                                    InsertLabel: 'Add New Office',
                                                    KeySource: 'UUID',
                                                    InsertForm: {
                                                        Elems: [
                                                            {   Label: 'Office Number',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'OfficeId',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Address 1',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Address1',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Address 2',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Address2',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'City',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'City',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'State',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'State',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Zip',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Zip',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Phone',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Phone',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Fax',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Fax',
                                                                Format: 'Input'
                                                            }
                                                        ],
                                                        SaveButtonLabel: 'Save',
                                                        CancelButtonLabel: 'Cancel'
                                                    },
                                                    Table: {
                                                        Elems: [
                                                            {   Label: 'Office Number',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'OfficeId',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Address 1',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Address1',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Address 2',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Address2',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'City',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'City',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'State',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'State',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Zip',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Zip',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Phone',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Phone',
                                                                Format: 'Input'
                                                            },
                                                            {   Label: 'Fax',
                                                                ElemType: 'Primitive',
                                                                ModelAttr: 'Fax',
                                                                Format: 'Input'
                                                            }
                                                        ],
                                                        Drilldown: {
                                                            Elems: [
                                                                {   Label: 'Office Number',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'OfficeId',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'Address 1',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'Address1',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'Address 2',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'Address2',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'City',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'City',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'State',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'State',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'Zip',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'Zip',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'Phone',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'Phone',
                                                                    Format: 'Input'
                                                                },
                                                                {   Label: 'Fax',
                                                                    ElemType: 'Primitive',
                                                                    ModelAttr: 'Fax',
                                                                    Format: 'Input'
                                                                }
                                                            ]
                                                        }
                                                    }
                                                }
                                                
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Wholesalers',
                        Attr: 'Wholesalers',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Wholesalers',
                                    ElemType: 'Child',
                                    ModelAttr: 'Wholesalers',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Name, etc. ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Id',
                                                Format: 'Input'
                                            },
                                            {   Label: 'First',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'First',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Last',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Last',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Mailing Address 1',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'MailingAddress1',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Mailing Address 2',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'MailingAddress2',
                                                Format: 'Input'
                                            },
                                            {   Label: 'City',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'City',
                                                Format: 'Input'
                                            },
                                            {   Label: 'ST',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'ST',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Zip',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Zip',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Cell',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Cell',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Id',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'First',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'First',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Last',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Last',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Mailing Address 1',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'MailingAddress1',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Mailing Address 2',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'MailingAddress2',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'City',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'City',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'ST',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'ST',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Zip',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Zip',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Cell',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Cell',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Distributions',
                        Attr: 'Accounts',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Distributions',
                                    ElemType: 'Child',
                                    ModelAttr: 'Accounts',
                                    Format: 'Table',
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Investor, Fund Name, Account Number, etc. ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Fund(s)',
                                                ElemType: 'Reference',
                                                ModelAttr: 'Fund',
                                                Format: 'Input',
                                                Filter: {
                                                    DefaultSelections: [
                                                        {Id: '001', Value: 'Emerson Equity Bridge Fund I, LLC', Selected: true},
                                                        {Id: '101', Value: 'IHC - Capital Fund', Selected: true},
                                                        {Id: '102', Value: 'IHC - Income Fund 1', Selected: false, Memo: '(Closed)'},
                                                        {Id: '103', Value: 'IHC - Income Fund 2', Selected: true},
                                                        {Id: '104', Value: 'IHC - Ashbrook DST', Selected: true},
                                                        {Id: '105', Value: 'IHC - Peachtree DST', Selected: true},
                                                        {Id: '106', Value: 'IHC - Las Vegas DST', Selected: true},
                                                        {Id: '107', Value: 'IHC - Candle Light Cove DST', Selected: true},
                                                        {Id: '108', Value: 'IHC - Athens DST', Selected: true},
                                                        {Id: '109', Value: 'IHC - Income Fund 3', Selected: true},
                                                        {Id: '110', Value: 'IHC - Brookhaven DST', Selected: true},
                                                        {Id: '111', Value: 'IHC - Carson Valley DST', Selected: true},
                                                        {Id: '112', Value: 'IHC - Arlington Heights DST', Selected: true},
                                                        {Id: '113', Value: 'IHC - Reno DST', Selected: true},
                                                        {Id: '114', Value: 'IHC - Naperville DST', Selected: true},
                                                        {Id: '115', Value: 'IHC - Hamilton DST', Selected: true},
                                                        {Id: '116', Value: 'IHC - Appleton DST', Selected: true},
                                                        {Id: '117', Value: 'IHC - Chesterfield', Selected: true},
                                                        {Id: '118', Value: 'IHC - Lake Orion', Selected: true},
                                                        {Id: '119', Value: 'IHC - Largo', Selected: true},
                                                        {Id: '120', Value: 'IHC - Pinellas Park', Selected: true},
                                                        {Id: '121', Value: 'IHC - Dunedin', Selected: true},
                                                        {Id: '122', Value: 'IHC - Fort Myers', Selected: true},
                                                        {Id: '123', Value: 'IHC - Melbourne', Selected: true},
                                                        {Id: '124', Value: 'IHC - Grapevine', Selected: true},
														{Id: '125', Value: 'IHC - Augusta', Selected: true},
														{Id: '126', Value: 'IHC - Round Rock', Selected: true},
														{Id: '127', Value: 'IHC - Delray Beach', Selected: true},
														{Id: '128', Value: 'IHC - Creswell Development', Selected: true},
														{Id: '129', Value: 'IHC - St. Petersburg', Selected: true},
														{Id: '130', Value: 'IHC - Mequon', Selected: true},
														{Id: '131', Value: 'IHC - Fund 5', Selected: true},
														{Id: '132', Value: 'IHC - Eugene DST', Selected: true},
														{Id: '133', Value: 'IHC - North Haven DST', Selected: true},
														{Id: '134', Value: 'IHC - Winery Lane Development DST', Selected: true},
														{Id: '135', Value: 'IHC - Dartmouth DST', Selected: true},
														{Id: '136', Value: 'IHC - Fund 5 Notes', Selected: true},
														{Id: '137', Value: 'IHC - San Marcos DST', Selected: true},
														{Id: '138', Value: 'IHC - New Braunfels DST', Selected: true},
														{Id: '139', Value: 'IHC - Development Fund lll, LLC', Selected: true}
                                                    ]
                                                }
                                            },
                                            {   Label: 'Account Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountNumber',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'asc',
                                                    Comparison: 'string',
                                                    Order: 2
                                                }
                                            },
                                            {   Label: 'Admit Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AdmitDate',
                                                Format: 'Input',
                                                Sortable : {
                                                    Dir: 'desc',
                                                    Comparison: 'date',
                                                    Order: 1
                                                }
                                            },
                                            {   Label: 'Capital Contributed',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'EquityAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Premium',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PremiumAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Distribution',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'PremiumAmount',
                                                Format: 'Mask',
                                                Mask: {
                                                    Type: 'Currency US'
                                                }
                                            },
                                            {   Label: 'Salutation',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Title',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Payment Instructions',
                                                ElemType: 'Embedded',
                                                ModelAttr: 'PaymentInstructions',
                                                Item: {
                                                    ModelAction: 'Update',
                                                    Elems: [
                                                        {   Label: 'Custodial',
                                                            ElemType: 'Extension',
                                                            ModelAttr: 'Custodial',
                                                            ShowRadioButton: true,
                                                            Format: 'Item',
                                                            Item: {
                                                                Elems: [
                                                                    {   Label: 'Custodian Name',
                                                                        ElemType: 'Reference',
                                                                        ModelAttr: 'Custodian',
                                                                        ModelAttrKey: 'Id',
                                                                        ModelAttrName: 'Name',
                                                                        Item: {
                                                                            ModelAction: 'Update',
                                                                            Elems: [
                                                                                {   Label: 'Custodians',
                                                                                    ElemType: 'Child',
                                                                                    ModelAttr: 'Custodians',
                                                                                    Format: 'FindAndUse',
                                                                                    KeySource: 'Criterion',
                                                                                    FindAndUse: {
                                                                                        Item: {
                                                                                            ModelAction: 'Watch',
                                                                                            Elems: [
                                                                                                {   Label: 'Id',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'Id',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Name',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'Name',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'ABA Number',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'ABANumber',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Custodian Account #',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'CustodianAccountNumber',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Check Payable',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'CheckPayable',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Payee Address Line 1',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'PayeeAddressLine1',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Payee Address Line 2',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'PayeeAddressLine2',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Payee City, State, Zip',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'PayeeCityStateZip',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Tax Id',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'TaxId',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Email',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'Email',
                                                                                                    Format: 'Input'
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    },
                                                                    {   Label: 'Custodian Account #',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'CustodianAccountNumber',
                                                                        Format: 'Input'
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {   Label: 'ACH',
                                                            ElemType: 'Extension',
                                                            ModelAttr: 'ACH',
                                                            ShowRadioButton: true,
                                                            Format: 'Item',
                                                            Item: {
                                                                Elems: [
                                                                    {   Label: 'ABA Number',
                                                                        ElemType: 'Reference',
                                                                        ModelAttr: 'ABANumber',
                                                                        ModelAttrKey: 'ABANumber',
                                                                        ModelAttrName: 'BankName',
                                                                        Item: {
                                                                            ModelAction: 'Update',
                                                                            Elems: [
                                                                                {   Label: 'ABA Numbers',
                                                                                    ElemType: 'Child',
                                                                                    ModelAttr: 'ABANumbers',
                                                                                    Format: 'FindAndUse',
                                                                                    KeySource: 'Criterion',
                                                                                    FindAndUse: {
                                                                                        Item: {
                                                                                            ModelAction: 'Watch',
                                                                                            Elems: [
                                                                                                {   Label: 'ABA Number',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'Id',
                                                                                                    Format: 'Input'
                                                                                                },
                                                                                                {   Label: 'Bank Name',
                                                                                                    ElemType: 'Primitive',
                                                                                                    ModelAttr: 'BankName',
                                                                                                    Format: 'Input'
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                        
                                                                        
                                                                    },
                                                                    {   Label: 'Account Number',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'AccountNumber',
                                                                        Format: 'Input'
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {   Label: 'Check',
                                                            ElemType: 'Extension',
                                                            ModelAttr: 'Check',
                                                            ShowRadioButton: true,
                                                            Format: 'Item',
                                                            Item: {
                                                                Elems: [
                                                                    {   Label: 'Check Payable',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'CheckPayable',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'Payee Address Line 1',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'PayeeAddressLine1',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'Payee Address Line 2',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'PayeeAddressLine2',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'Payee: City, State, Zip',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'PayeeCityStateZip',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'Memo',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'Memo',
                                                                        Format: 'Input'
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {   Label: 'Wire',
                                                            ElemType: 'Extension',
                                                            ModelAttr: 'Wire',
                                                            ShowRadioButton: true,
                                                            Format: 'Item',
                                                            Item: {
                                                                Elems: [
                                                                    {   Label: 'Bank Name',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'BankName',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'SWIFT/ABA Number',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'SWIFTABANumber',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'Custodian Account #',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'CustodianAccountNumber',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'Payee Account #',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'PayeeAccountNumber',
                                                                        Format: 'Input'
                                                                    },
                                                                    {   Label: 'Memo/FFC',
                                                                        ElemType: 'Primitive',
                                                                        ModelAttr: 'MemoFFC',
                                                                        Format: 'Input'
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {   Label: 'ABA Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DFI_NUM_WITH_CHECK',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Account Number',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DFI_ACC_NUM',
                                                Format: 'Input'
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Transaction Codes',
                        Attr: 'TransactionCodes',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Transaction Codes',
                                    ElemType: 'Child',
                                    ModelAttr: 'TransactionCodes',
                                    Format: 'Table',
                                    Insertable: true,
                                    InsertLabel: 'Add New Transaction Code',
                                    KeySource: 'UUID',
                                    InsertForm: {
                                        Elems: [
                                            {   Label: 'Code',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Code',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Description',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Description',
                                                Format: 'Input'
                                            }
                                        ],
                                        SaveButtonLabel: 'Save',
                                        CancelButtonLabel: 'Cancel'
                                    },
                                    Table: {
                                        Elems: [
                                            {   Label: 'Code',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Code',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Description',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Description',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Code',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Code',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Description',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Description',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {   Label: 'Transactions',
                        Attr: 'Transactions',
                        Icon: 'home',
                        FontSize: '24px',
                        Item: {
                            ModelAction: 'Update',
                            Elems: [
                                {   Label: 'Transactions',
                                    ElemType: 'Child',
                                    ModelAttr: 'Transactions',
                                    Format: 'Table',
                                    Insertable: true,
                                    InsertLabel: 'Add New Transaction',
                                    KeySource: 'UUID',
                                    InsertForm: {
                                        Elems: [
                                            {   Label: 'Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Id',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Transaction Code',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TransactionCode',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TransactionDate',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Time',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TransactionTime',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Account',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Amount',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Debit Or Credit',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DebitOrCredit',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Memo',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Memo',
                                                Format: 'Input'
                                            }
                                        ],
                                        SaveButtonLabel: 'Save',
                                        CancelButtonLabel: 'Cancel'
                                    },
                                    Table: {
                                        SearchBox : {
                                            Prompt: 'Search by Code, Date, Account, etc. ...'
                                        },
                                        ExportExcel: {
                                            Label: 'Export to Excel'
                                        },
                                        Elems: [
                                            {   Label: 'Id',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Id',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Transaction Code',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TransactionCode',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Date',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TransactionDate',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Time',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'TransactionTime',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Account',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'AccountId',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Amount',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Amount',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Debit Or Credit',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'DebitOrCredit',
                                                Format: 'Input'
                                            },
                                            {   Label: 'Memo',
                                                ElemType: 'Primitive',
                                                ModelAttr: 'Memo',
                                                Format: 'Input'
                                            }
                                        ],
                                        Drilldown: {
                                            Elems: [
                                                {   Label: 'Id',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Id',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Transaction Code',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'TransactionCode',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Date',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'TransactionDate',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Time',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'TransactionTime',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Account',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'AccountId',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Amount',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Amount',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Debit Or Credit',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'DebitOrCredit',
                                                    Format: 'Input'
                                                },
                                                {   Label: 'Memo',
                                                    ElemType: 'Primitive',
                                                    ModelAttr: 'Memo',
                                                    Format: 'Input'
                                                }
                                            ],
                                            SaveButtonLabel: 'Save',
                                            CancelButtonLabel: 'Cancel'
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ],
                Extensions: {
                    Super: {
                        Alternatives: [
                        ]
                    }
                }
            }
        }
    }
};

const ControllerSpec = {
    IHC:        ControllerSpecIHC
};

// For engine
//module.exports = {ControllerSpec: ControllerSpec};

// For browser
export {ControllerSpec};
