import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Item from './Item1.js';

const Track = (props) => {
    const classes = useStyles();
    let itemTip = props.ViewTrack.viewItem.active.CrumbTrail != null && props.ViewTrack.viewItem.active.CrumbTrail.length > 0 ?
            props.ViewTrack.viewItem.active.CrumbTrail[props.ViewTrack.viewItem.active.CrumbTrail.length-1]
        : 
            props.ViewTrack.viewItem;
    return (
        <div> 
            <Item
                ViewItem={itemTip}
                ViewItemBase={props.ViewTrack.viewItem}
            />
        </div>
    )
}

export default Track;


const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },    
}));
