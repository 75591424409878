export class Transmitter {
    constructor(context) {
        this.context = context;
        this.contextHere = {
            ...this.context,
            Transmitter: this
        };
        this.websocketBE = null;
        this.websocketBEIsActive = false;
        this.sessionId = null;
    }
    
    startSessionServer(objectController, callbackController, url) {
        this.objectController = objectController;
        this.callbackController = callbackController;
        this.websocketBE = new WebSocket(url);
        setTimeout(() => { this.keepAlive(); }, 30000);
        this.websocketBE.onopen = () => {
            this.websocketBEIsActive = true;
            console.log("Websocket opened");
        };
        this.websocketBE.onclose = (e) => {
            this.websocketBEIsActive = false;
            var reason = 'Unknown error';
            switch(e.code) {
                case 1000:
                  reason = 'Normal closure';
                  break;
                case 1001:
                  reason = 'An endpoint is going away';
                  break;
                case 1002:
                  reason = 'An endpoint is terminating the connection due to a protocol error.';
                  break;
                case 1003:
                  reason = 'An endpoint is terminating the connection because it has received a type of data it cannot accept';
                  break;
                case 1004:
                  reason = 'Reserved. The specific meaning might be defined in the future.';
                  break;
                case 1005:
                  reason = 'No status code was actually present';
                  break;
                case 1006:
                  reason = 'The connection was closed abnormally';
                  break;
                case 1007:
                  reason = 'The endpoint is terminating the connection because a message was received that contained inconsistent data';
                  break;
                case 1008:
                  reason = 'The endpoint is terminating the connection because it received a message that violates its policy';
                  break;
                case 1009:
                  reason = 'The endpoint is terminating the connection because a data frame was received that is too large';
                  break;
                case 1010:
                  reason = 'The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn\'t.';
                  break;
                case 1011:
                  reason = 'The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.';
                  break;
                case 1012:
                  reason = 'The server is terminating the connection because it is restarting';
                  break;
                case 1013:
                  reason = 'The server is terminating the connection due to a temporary condition';
                  break;
                case 1015:
                  reason = 'The connection was closed due to a failure to perform a TLS handshake';
                  break;
                default:
                  break;
            }

            // Show 'closed' message on screen
            console.log("Websocket close error: " + reason);
        };
        this.websocketBE.onmessage = (evt) => {
            var messageInParsed = JSON.parse(evt.data);
            //console.log("startSessionServer: ", messageInParsed);
            if (messageInParsed.SessionId != null) {
                if (this.sessionId == null) {
                    this.sessionId = messageInParsed.SessionId;
                    this.objectController[this.callbackController](messageInParsed);
                } else {
                    if (messageInParsed.SessionId === this.sessionId) {
                        this.objectController[this.callbackController](messageInParsed);
                    }
                }
            } else {
                this.objectController[this.callbackController](messageInParsed);
            }
        };
        this.websocketBE.onerror = (ev) => {
            console.log("Websocket open error: " + ev.data);
        }
    }

    keepAlive() {
        //console.log("keepAlive");
        this.sendMessageToBE('{}'); 
        setTimeout(() => { this.keepAlive(); }, 30000);
    }
    
    sendMessageToBE(message) {
        if (this.websocketBEIsActive === true) {
            this.websocketBE.send(message);
        }
    }

}

