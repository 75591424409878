import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import './index.css';
import App from './view/ui/UIBaseComponents/App1.js';
import {UIConfig} from './view/ui/UIConfig.js';
import {Transmitter} from './view/ui/Transmitter.js';
import {Controller} from './controller/Controller.js';

// Add all icons to the library so you can use it in your page
library.add(fas)

if (window.controller == null) {
    window.transmitter = new Transmitter({});
    window.controller = new Controller({}, UIConfig, window.transmitter, null);
}

ReactDOM.render(
  <Provider store={window.controller.storeRedux}>
    <App />
  </Provider>,
  document.getElementById('root')
);
