import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import jwt_decode from "jwt-decode";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

const checkUserAuthentication = (viewSignInOrUp) => {
    if (window.controller.transmitter.websocketBEIsActive === true) {
        if (viewSignInOrUp.IsAuthenticated === false) {
            if (document.location.hash != null) {
                let cognitoData = {}; 
                let elementsString = decodeURIComponent(document.location.hash.substr(1, document.location.hash.length));
                let params = elementsString.split("&");
                for (let param of params) {
                    let values = param.split("=");
                    cognitoData[values[0]] = values[1];
                }
                if (cognitoData["id_token"] != null) {
                    let idDecoded = jwt_decode(cognitoData["id_token"], {header: false});
                    viewSignInOrUp.UserId = idDecoded.email.toLowerCase();
                    viewSignInOrUp.IsAuthenticated = true;
                    window.controller.alertFromView('TO_MODEL',
                        'Watch', 
                        ['Profiles', viewSignInOrUp.UserId], 
                        ['SignInOrUp'], 
                        {}
                    );
                }
            }
        }
    } else {
        setTimeout(() => { checkUserAuthentication(viewSignInOrUp); }, 10);
    }
}

const SignInOrUp = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const loginMenuOpen = Boolean(anchorEl);    
    const handleLoginMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLoginClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        handleLoginClose();
        props.ViewSignInOrUp.IsAuthenticated = false;
        window.location = props.ViewSignInOrUp.RedirectURI;
    };    
    React.useEffect(() => {
        checkUserAuthentication(props.ViewSignInOrUp);
    });
    return (
        <div> { props.ViewSignInOrUp.IsAuthenticated === false ?
            <Button color="inherit" href={props.ViewSignInOrUp.RedirectURI}  style={{fontSize: '20px', textAlign: 'center', border: 'solid', borderWidth: '3px', paddingLeft: '2em', paddingRight: '2em'}} >Investor/Advisor Sign In</Button>
        :
            <div>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleLoginMenu}
                    color="inherit"
                >
                    <AccountCircle />
                    &nbsp;&nbsp;&nbsp;
                    {
                        <Typography variant="subtitle1" noWrap className={classes.title}>
                            {props.ViewSignInOrUp.UserId}
                        </Typography>
                    }
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={loginMenuOpen}
                    onClose={handleLoginClose}
                >
                    {/*
                    <MenuItem onClick={handleLoginClose}>Profile</MenuItem>
                    <MenuItem onClick={handleLoginClose}>My account</MenuItem>
                    */}
                    { props.ViewSignInOrUp.Model != null ?
                        <MenuItem onClick={handleLoginClose}>{props.ViewSignInOrUp.model.Attrs.Name.Value}</MenuItem>
                    :
                        ''
                    }
                    <MenuItem onClick={handleLogout} >Sign off</MenuItem>
                </Menu>
            </div>
        } </div>
    );
}

export default SignInOrUp;

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },    
}));
