import React from 'react';
import {useSelector} from "react-redux"; 
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import SignInOrUp from './SignInOrUp.js';
import Track from './Track.js';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const getViewApp = () => {
    if (window.controller != null) {
        console.log("Referrer: ", document.referrer);
        document.title = window.controller.controllerSpec.UI.Label;
        return(window.controller.rootView);
    } else {
        return(null);
    }
}

const getViewSpec = () => {
    if (window.controller != null) {
        return(window.controller.viewSpec);
    } else {
        return(null);
    }
}

const App = () => {
    useSelector(state => state.reduxAlertFromController);
    let viewApp = getViewApp();
    let viewSpec = getViewSpec();
    const classes = useStyles();
    const [popupOpen, setPopupOpen] = React.useState(true);
    const [sampleRequest, setSampleRequest] = React.useState({
        email: '',
        name: '',
        streetaddress: '',
        city: '',
        state: '',
        zip: '',
        country: ''
    });
    const handleClickPopupOpen = () => {
        setPopupOpen(true);
    };
    const handlePopupClose = () => {
        setPopupOpen(false);
    };    
    const handlePopupSubmit = () => {
        if (sampleRequest.email.length < 7 ||
            sampleRequest.email.includes("@") === false ||
            sampleRequest.name.length < 2 ||
            sampleRequest.streetaddress.length < 7 ||
            sampleRequest.city.length < 3 ||
            (sampleRequest.state.length < 2 && sampleRequest.country.substr(0,2).toLowerCase() === 'us') ||
            sampleRequest.zip.length < 3 ||
            sampleRequest.country.length < 2
            ) {
            alert("Please fill in all information");
        } else {
            setPopupOpen(false);
            alert("Thank you!");
            const formData = new FormData();
            let name = "FreeSampleRequest_"+sampleRequest.email.replace(/\s/g, "").replace("@", "_at_").substr(0, 60);
            let stringSampleRequest = JSON.stringify(sampleRequest);
            if (name.length > 25 && stringSampleRequest.length < 300) {
                let blob = new Blob([stringSampleRequest], {type:'application/json'});
                formData.append(name, blob, name);
                const url = 'https://'+window.controller.viewConfig.HostName+':'+window.controller.viewConfig.UploadPort;
                fetch(url, {
                    method: 'POST',
                    body: formData
                }).then(response => {
                    return response.text();
                }).then(data => {
                    console.log(data);
                });
            }
        }
    };    
    return(
        <div className={classes.root}>
            <CssBaseline />
            {/*
            <AppBar position="fixed" className={clsx(classes.appBar)}>
            </AppBar>                
            */}
            {   window.controller.isModelConnected ?
                <main className={clsx(classes.content)} > 
                
                
                    { viewSpec.Masthead != null ? 
                        <div className={classes.masthead} 
                                style={{display: 'flex', backgroundColor: viewSpec.Masthead.MastheadBackgroundColor, color: viewSpec.Masthead.MastheadFontColor, marginTop: viewSpec.Masthead.MarginTop, 
                                justifyContent: 'space-between', maxHeight: '200px'}}>
                            { viewSpec.Label === 'IHC Portal' && window.controller.signInOrUp.IsAuthenticated === false ?
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flexGrow: 1, padding: '1em'}}>
                                    <SignInOrUp
                                        ViewSignInOrUp={window.controller.signInOrUp}
                                    />
                                </div>
                            :
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '2em', flexGrow: 1}}>
                                    <Typography variant="h6">
                                        {viewSpec.Label}
                                    </Typography>
                                    <Typography variant="subtitle2" display="block" >
                                        {viewSpec.ContactInfo.Line1}
                                    </Typography>
                                    <Typography variant="subtitle2" display="block" >
                                        {viewSpec.ContactInfo.Line2}
                                    </Typography>
                                </div>
                                
                                <img src={viewSpec.Logo.img} style={{width: viewSpec.Logo.width, flexGrow: 1}} alt='' />
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1em', fontSize: '36px', flexGrow: 1}}>
                                    <SignInOrUp
                                        ViewSignInOrUp={window.controller.signInOrUp}
                                    />
                                </div>
                                </div>
                            }
                        </div>                
                    :
                        ''
                    }
                    
                    
                    
                    
                    { window.controller.signInOrUp.IsAuthenticated === true && window.controller.tracks.length > 0 && window.controller.tracks[0].viewItem != null?
                        <Track
                            ViewTrack={window.controller.tracks[0]}
                        />
                    :
                        <div>
                            {   document.referrer != null && 
                                (document.referrer.includes('facebook') || document.referrer.includes('instagram')  || document.referrer.includes('t.co')) && 
                                viewSpec.Label === 'Pinch' ?
                                <Dialog open={popupOpen} onPopupClose={handlePopupClose}>
                                    <DialogTitle>Request Free Sample</DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                        Please provide your email and mailing address to receive a free sample.
                                      </DialogContentText>
                                      <TextField
                                        autoFocus
                                        margin="dense"
                                        id="email"
                                        label="Email Address"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                        onBlur={e => {
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            let sampleRequestNew = {...sampleRequest};
                                            sampleRequestNew.email = e.target.value;
                                            setSampleRequest(sampleRequestNew);
                                        }} 
                                        
                                      />
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={handlePopupClose}>Cancel</Button>
                                      <Button onClick={handlePopupSubmit}>Submit</Button>
                                    </DialogActions>
                                </Dialog>                                
                            :
                                ''
                            }
                            {   viewSpec.Splash != null ?
                                <img src={viewSpec.Splash.img} 
                                    style={{width: '100%', height: '100%', paddingTop: '30px'}} alt=''
                                />
                            :
                                ''
                            }
                        </div>
                    } 
                </main>
            :
                <Typography variant="h4" noWrap className={classes.starting}>
                    Starting ...
                </Typography>                    
            }
        </div>
    );
}

export default App;

const width_sm = 600;
const width_md = 900;
const width_lg = 1200;
const width_xl = 1500;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(5),
        //backgroundColor: theme.palette.secondary.main,
        margin: 'auto',
        marginWidth: '30px',
        [theme.breakpoints.up("sm")]: {
          width: width_sm+'px',
        },
        [theme.breakpoints.up("md")]: {
          width: width_md+'px',
        },
        [theme.breakpoints.up("lg")]: {
          width: width_lg+'px',
        },
        [theme.breakpoints.up("xl")]: {
          width: '100%',
        },
    },
    appBar: {
        position: 'absolute',
        left: 0,
        margin: 'auto',
        marginWidth: '30px',
        [theme.breakpoints.up("sm")]: {
          width: width_sm+'px',
        },
        [theme.breakpoints.up("md")]: {
          width: width_md+'px',
        },
        [theme.breakpoints.up("lg")]: {
          width: width_lg+'px',
        },
        [theme.breakpoints.up("xl")]: {
          width: '100%',
        },
    },
    masthead: {
        [theme.breakpoints.up("sm")]: {
          width: width_sm+'px',
        },
        [theme.breakpoints.up("md")]: {
          width: width_md+'px',
        },
        [theme.breakpoints.up("lg")]: {
          width: width_lg+'px',
        },
        [theme.breakpoints.up("xl")]: {
          width: width_xl+'px',
        },
    },
    title: {
        flexGrow: 1,
    },    
    starting: {
        flexGrow: 1,
        margin: 120,
    },    
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
}));
