import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ItemElem from './ItemElem.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

const saveItem = (props) => {
    window.controller.alertFromView('TO_VIEW',
        'SaveRequested', 
        [...props.ViewItem.active.ModelPath], 
        [...props.ViewItem.active.ViewPath],
        props.ViewItem.Model,
        props.ViewItem
    );
}
const Item = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.ViewItem.alternativePicked = newValue;
        window.controller.alertFromView('TO_VIEW',
            'AlternativePicked', 
            [...props.ViewItem.active.ModelPath], 
            [...props.ViewItem.active.ViewPath],
            props.ViewItem.Model,
            props.ViewItem
        );
    };    
    let menuLocation = props.ViewItemBase.viewSpec.MenuLocation != null ? props.ViewItemBase.viewSpec.MenuLocation : 'Left';
    return (
        <div className={classes.root} style={{display: 'flex', justifyContent: 'flex-start', flexDirection: menuLocation === 'Left' ? 'row' : 'column'}}>
            { props.ViewItemBase.viewSpec.Alternatives != null ?
                <Tabs 
                    value={value} 
                    aria-label="tabs" 
                    indicatorColor="secondary" 
                    textColor="#ffffff"
                    onChange={handleChange}
                    variant="fullWidth"
                    orientation={menuLocation === 'Left' ? 'vertical' : 'horizontal'}
                >   {
                        props.ViewItemBase.viewSpec.Alternatives.map((alternativeCur, alternativeIndex) => 
                            <Tab key={alternativeIndex} label={alternativeCur.Label} style={{color: '#ffffff', fontWeight: 'bold'}} />
                        )
                    } 
                </Tabs>
            :
                ''
            }
            <div style={{color: 'black', backgroundColor: 'white', width: '100%', padding: '1em'}}>
                { props.ViewItem.active.CrumbTrail != null && props.ViewItem.active.CrumbTrail.length > 0 ?
                    <div>
                        <IconButton 
                            key={10}
                            aria-label="back"
                            onClick={e => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                props.ViewItem.active.CrumbTrail.pop();
                                window.controller.alertFromView('TO_VIEW',
                                    'CrumbPicked', 
                                    [...props.ViewItem.active.ModelPath], 
                                    [...props.ViewItem.active.ViewPath],
                                    null,
                                    props.ViewItemBase
                                );
                            }} 
                        >
                            <ArrowBackIcon />
                            Go back
                        </IconButton>
                        <Breadcrumbs> { props.ViewItem.active.CrumbTrail.map((crumbCur, crumbIndex) =>
                            <span> { crumbIndex < (props.ViewItem.active.CrumbTrail.length-1) ?
                                <Link key={crumbIndex} color="inherit"  
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        /*
                                        let i = 0;
                                        do {
                                            props.ViewItem.active.CrumbTrail.pop();
                                        } while (crumb.Pos > 0);
                                        window.controller.alertFromView('TO_VIEW',
                                            'CrumbPicked', 
                                            null, 
                                            null,
                                            null,
                                            props.ViewItemBase
                                        );
                                        */
                                    }}
                                >
                                    {crumbCur.Label}
                                </Link>
                            :
                                <Typography color="textPrimary">{crumbCur.Label}</Typography>
                            } </span>
                        )} </Breadcrumbs>
                    </div>
                :
                    ''
                }
                { props.ViewItem.active.Elems != null && props.ViewItem.active.Elems.map((elemCur, elemIndex) =>
                    <div>
                        { elemCur.viewSpec != null ?
                            <ItemElem
                                ViewItemElem={elemCur}
                                ItemElemIndex={elemIndex}
                                SaveableItem={props.ViewItem.active.ViewSpec.SaveButtonLabel != null ? props.ViewItem : null}
                            />
                        :
                            <span>{elemCur.key}</span>
                        }
                    </div>
                    )
                }
                {   (props.ViewItem.active.ViewSpec.SaveButtonLabel != null) ?
                    <Button variant="contained" color="primary" className={classes.margin}
                        disabled={!props.ViewItem.isDirty}
                        onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            if (props.ViewItem.active.UploadFiles != null) {
    
                                // KLUDGE for IHC tax report file upload
                                //let dateText = props.View.Elems.find(cur => cur.SpecElem.ModelAttr === 'DateText');
                                props.ViewItem.active.UploadFiles.forEach(cur => {
                                    const formData = new FormData();
                                    let name = cur.Name;
                                    //if (dateText != null) {
                                    //    name += ('-'+ dateText.Value);
                                    //}
                                    let filesegs = cur.FileData.name.split('.');
                                    if (filesegs.length > 0) {
                                        name += ('.'+ filesegs.pop());
                                    }
                                    //if (name.includes('/')) {
                                    //    name = name.replace(/[/]/g, '-');
                                    //}
                                    formData.append(name, cur.FileData, name);
                                    if (props.ViewItem.active.Elems != null) {
                                        props.ViewItem.active.Elems.forEach(elemCur => {
                                            if (elemCur.SpecElem.ElemType === 'Primitive' && elemCur.SpecElem.ModelAttr === cur.ModelAttr && elemCur.SpecElem.Format === 'Upload') {
                                                elemCur.Value = name;
                                            }
                                        });
                                    }
                                    const url = 'https://'+props.Controller.viewConfig.HostName+':'+props.Controller.viewConfig.UploadPort;
                                    fetch(url, {
                                        method: 'POST',
                                        body: formData
                                    }).then(response => {
                                        return response.text();
                                    }).then(data => {
                                        console.log(data);
                                    });
                                });
                                props.ViewItem.active.UploadFiles = null;
                            }
                            saveItem(props);
                        }} 
                    >
                    {props.ViewItem.active.ViewSpec.SaveButtonLabel}
                    </Button>
                :
                    null
                }
                {   (props.ViewItem.active.ViewSpec.CancelButtonLabel != null) ?
                    <Button variant="contained" color="secondary" className={classes.margin}
                        onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            props.ViewItem.active.CrumbTrail.pop();
                            window.controller.alertFromView('TO_VIEW',
                                'CrumbPicked', 
                                [...props.ViewItem.active.ModelPath], 
                                [...props.ViewItem.active.ViewPath],
                                null,
                                props.ViewItemBase
                            );
                        }}
                        >
                        {props.ViewItem.active.ViewSpec.CancelButtonLabel}
                    </Button>
                :
                    null
                }
                {/*   (props.View.UpdateStatus === 'UpdateRequested') ?
                    <div>
                        <Typography className={classes.title} variant="h6" id="saving" component="div">
                            Saving ...      
                        </Typography>
                    </div>
                :
                    (props.View.UpdateStatus === 'UpdateCompleted') ?
                        <Typography className={classes.title} variant="h6" id="saving" component="div">
                            Saved!      
                        </Typography>
                :
                    null
                */}
            </div>
        </div>
    )
}

export default Item;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#82c0f2'
    },    
}));
