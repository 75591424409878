import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Reference from './Reference1.js';
import Child from './Child1.js';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';

let investor = -1;

const ItemElem = (props) => {
    const classes = useStyles();
    return (
        <div>
        { props.ViewItemElem.viewSpec != null && props.ViewItemElem.viewSpec.ElemType === 'Child' ? 
            <Child
                ViewChild={props.ViewItemElem}
            />
        :
        props.ViewItemElem.viewSpec != null && props.ViewItemElem.viewSpec.ElemType === 'Constant' ?
            props.ViewItemElem.viewSpec.Format === 'File' && props.ViewItemElem.viewSpec.Value != null  ?
                <div>
                    <a href={"/files/"+props.ViewItemElem.viewSpec.Value} download
                        onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                        }} 
                    >
                        {props.ViewItemElem.viewSpec.Value}
                    </a>
                </div>
            :
                <div>
                    {props.ViewItemElem.viewSpec.Value != null ? props.ViewItemElem.viewSpec.Value : ''}
                </div>

        :
        props.ViewItemElem.viewSpec != null && props.ViewItemElem.viewSpec.ElemType === 'Primitive' ?
            props.ViewItemElem.viewSpec.Format === 'Video' && props.ViewItemElem.Value != null  ?
                <div> 
                    <br />
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {props.ViewItemElem.viewSpec.Label}
                    </Typography>
                    <video width={props.ViewItemElem.viewSpec.Mask.Width} height={props.ViewItemElem.viewSpec.Mask.Height} controls>
                      <source src={"/files/"+JSON.parse(props.ViewItemElem.Value).uuid } type="video/mp4" />
                    </video>      
                </div>
            :
                props.ViewItemElem.viewSpec.Format === 'Image' && props.ViewItemElem.Value != null  ?
                    <div> 
                        <br />
                        <br />
                        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                            {props.ViewItemElem.viewSpec.Label}
                        </Typography>
                        <br />
                        <img src={"/files/"+JSON.parse(props.ViewItemElem.Value).uuid} 
                            style={{width: props.ViewItemElem.viewSpec.Mask.Width+'px'}} alt=''
                        />                                                       
                    </div>
                :
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography variant="subtitle1" style={{minWidth: '20%'}}>
                            {props.ViewItemElem.viewSpec.Label+':'}
                        </Typography>
                        <TextField key={props.ItemElemIndex} style={{width: '90%'}}
                            id={props.ViewItemElem.viewSpec.Label} 
                            fullwidth={"false"}
                            key={props.ViewItemElem.Value != null ? props.ViewItemElem.Value : ''}
                            defaultValue={props.ViewItemElem.Value != null ? props.ViewItemElem.Value : ''}
                            disabled={props.ViewItemElem.viewSpec.Disabled != null ? props.ViewItemElem.viewSpec.Disabled : false}
                            className={classes.textField}
                            InputProps={{ disableUnderline: props.ViewItemElem.viewSpec.Disabled != null ? props.ViewItemElem.viewSpec.Disabled : false }}
                            onBlur={e => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                props.ViewItemElem.Value = e.target.value;
                                if (props.SaveableItem != null) {
                                    props.SaveableItem.isDirty = true;
                                    window.controller.alertFromView('TO_VIEW',
                                        'ReRender', 
                                        [...props.SaveableItem.active.ModelPath], 
                                        [...props.SaveableItem.active.ViewPath],
                                        props.SaveableItem.Model,
                                        props.SaveableItem
                                    );
                                }
                            }} 
                        />
                    </div>
        :
        props.ViewItemElem.viewSpec != null && props.ViewItemElem.viewSpec.ElemType === 'Embedded' ?
            <div PVI_Embedded_Label={props.ViewItemElem.viewSpec.Label} style={{width: '90%'}}>
                {props.ViewItemElem.viewSpec.Label}:<br />
                { props.ViewItemElem.viewSpec.Item.Elems.map((elemCur, elemIndex) =>
                    <div>
                        { elemCur.ElemType === 'Extension' ?
                            <div PVI_Elem_Type="Extension" PVI_Elem_Label={elemCur.Label}> 
                                {   elemCur.ShowRadioButton != null && elemCur.ShowRadioButton === true ?
                                    <div>
                                        <Radio
                                            checked={props.SaveableItem.model.Attrs[props.ViewItemElem.viewSpec.ModelAttr].ExtensionPath[0] === elemCur.Label ? true : false}
                                            onChange={e => {
                                                e.stopPropagation();
                                                e.nativeEvent.stopImmediatePropagation();
                                                props.ViewItemElem.Value = elemCur.Label;
                                                props.SaveableItem.model.Attrs[props.ViewItemElem.viewSpec.ModelAttr].ExtensionPath[0] = elemCur.Label;
                                                props.SaveableItem.isDirty = true;
                                                window.controller.alertFromView('TO_VIEW',
                                                    'ReRender', 
                                                    [...props.SaveableItem.active.ModelPath], 
                                                    [...props.SaveableItem.active.ViewPath],
                                                    props.SaveableItem.Model,
                                                    props.SaveableItem
                                                );
                                                
                                            }}
                                            value={elemCur.Label}
                                            name={props.ViewItemElem.viewSpec.Label}
                                            inputProps={{}}
                                        />    
                                        {elemCur.Label}  

                                        { props.SaveableItem.model.Attrs[props.ViewItemElem.viewSpec.ModelAttr].ExtensionPath[0] === elemCur.Label ?
                                            <div style={{border: 'solid', borderWidth: '1px', marginLeft: '3em', padding: '1em'}}>
                                            { elemCur.Item.Elems.map((elemSubCur, elemSubIndex) =>
                                                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                                    <Typography variant="subtitle1" style={{minWidth: '20%'}}>
                                                        {elemSubCur.Label+':'}
                                                    </Typography>
                                                    <TextField style={{width: '70%'}}
                                                        key={elemSubIndex}
                                                        id={elemSubCur.Label} 
                                                        fullwidth={"true"}
                                                        defaultValue={props.ViewItemElem.item.active.Elems[elemIndex].item.active.Elems[elemSubIndex].Value != null ? 
                                                            props.ViewItemElem.item.active.Elems[elemIndex].item.active.Elems[elemSubIndex].Value : ''}
                                                        className={classes.textField}
                                                    />
                                                </div>
                                                )
                                            }
                                            </div>
                                        :
                                            ''
                                        }
                                    </div>
                                :
                                    ''
                                }
                            </div>
                        :
                            ''
                        }
                    </div>
                    )
                }
            </div>
        :
            ''
        }
        </div>
    )
}

/*
                                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
 * */

export default ItemElem;

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },    
    textField: {
        '& input:disabled': {
          color: '#505050',
        },
    },
}));

